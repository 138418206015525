// src/Components/CustomerDashboard/PhysicalServersTree.jsx

import { Column } from 'primereact/column';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { Tree } from 'primereact/tree';
import { TreeTable } from 'primereact/treetable';
import React, { useEffect, useState, useTransition } from 'react';
import api from '../services/api';
import CardSkeleton from '../Skeletons/Card';
import { isSessionExpiredError } from '../utils/Common';
import { showToast } from '../utils/toastService';

const PhysicalServersTree = ({ customerId }) => {
  const [nodes, setNodes] = useState([]);
  const [isPending, startTransition] = useTransition();


  useEffect(() => {
    if (!customerId) return;

    const fetchServers = async () => {
      try {
        const response = await api.get(`/physicalservers/tenant/${customerId}`);
        const data = response.data || [];
        const treeData = data.map((server) => ({
          key: server.id,
          label: server.name,
          type: 'server',
          data: { health: server.health },
          children: [
            {
              key: `volumeTable-${server.id}`,
              type: 'volumeTable',
              volumes: (server.Volume || []).filter((vol) => vol.name !== null),
            },
          ],
        }));

        startTransition(() => {
          setNodes(treeData); // Ensure this is only run inside transition
        });
      } catch (error) {
        setNodes([]);
        if (isSessionExpiredError(error)) {
          showToast('warn', 'Session Expired', 'Your session has expired. Please log in again.');
        } else {
          showToast('error', 'Error', 'Failed to fetch physical servers.');
        }
        console.error('Error fetching physical servers:', error);
      }
    };

    fetchServers();
  }, [customerId, startTransition]); // ✅ Ensure dependencies are correct


  const nodeTemplate = (node) => {
    switch (node.type) {
      case 'server':
        return renderServerNode(node);
      case 'volumeTable':
        return renderVolumeTableNode(node);
      default:
        return <span>{node.label}</span>;
    }
  };

  const renderServerNode = (node) => (
    <div className="d-flex p-d-flex p-ai-center p-jc-between" style={{ width: '100%' }}>
      <span style={{ fontWeight: 'bold' }}>Server: {node.label}</span>
      {renderHealthTag(node?.data?.health)}
    </div>
  );

  const renderVolumeTableNode = (node) => {
    const volumeNodes = node.volumes.map((vol) => {
      let usedGB = vol.sizeGB - vol.freeSpaceGB;
      let percentage = vol.sizeGB > 0 ? Math.round((usedGB / vol.sizeGB) * 100) : null;

      return {
        key: vol.id,
        data: {
          name: vol.name,
          status: vol.status,
          sizeGB: vol.sizeGB,
          freeSpaceGB: vol.freeSpaceGB,
          usedGB,
          percentage,
          fileSystem: vol.fileSystem,
        },
      };
    });

    return (
      <div style={{ marginTop: '0.5rem' }}>
        <TreeTable value={volumeNodes}>
          <Column field="name" header="Name" expander />
          <Column field="status" header="Status" body={renderVolumeStatusTag} />
          <Column field="percentage" header="Used" body={renderUsedProgress} />
          <Column field="sizeGB" header="Size (GB)" />
          <Column field="fileSystem" header="File System" />
        </TreeTable>
      </div>
    );
  };

  const renderUsedProgress = (node) => {
    if (node.data.percentage != null) {
      return <ProgressBar value={node.data.percentage} />;
    }
    return null;
  };

  const renderHealthTag = (health) => {
    let severity = 'info';
    switch (health) {
      case 'Healthy':
        severity = 'success';
        break;
      case 'Warning':
        severity = 'warning';
        break;
      case 'Critical':
        severity = 'danger';
        break;
      default:
        severity = 'success';
    }
    return <Tag className='ml-4' value={health || 'Healthy'} severity={severity} />;
  };

  const renderVolumeStatusTag = (node) => {
    let status = node.data.status?.toString() || 'Unknown';
    let severity = 'info';

    switch (status.toLowerCase()) {
      case 'healthy':
        severity = 'success';
        break;
      case 'warning':
        severity = 'warning';
        break;
      case 'critical':
      case 'failed':
        severity = 'danger';
        break;
      default:
        severity = 'info'; // Default case added
    }

    return <Tag value={status} severity={severity} />;
  };


  return (
    <div className='p-grid p-dir-col'>
      {isPending ? (<CardSkeleton />) : (
        <div className="p-col">
          <Tree value={nodes || []} nodeTemplate={nodeTemplate} />
        </div>
      )}

    </div>
  );
};

export default PhysicalServersTree;
