// src/Pages/CustomerLandingPage/CustomerLandingPage.jsx

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import React from 'react';
import { redirect } from 'react-router-dom'; // Updated import

const CustomerLandingPage = () => {
  const handleGetStarted = () => {
    redirect('/getting-started');
  };

  const handleProfileSetup = () => {
    redirect('/profile-setup');
  };

  const handleExploreFeatures = () => {
    redirect('/features');
  };

  return (
    <div className="p-grid customer-landing-container">
        <div className="p-col-12 p-md-9 content-col">
          <div className="landing-content p-mt-4">
            {/* Welcome Section */}
            <Card className="welcome-card p-shadow-3">
              <div className="welcome-content">
                <h2>Welcome to the Sky Data Vault Portal!</h2>
                <p>
                  We're excited to have you on board. Sky Data Vault offers a comprehensive suite of tools to help you manage your needs efficiently and effectively.
                </p>
                <Button label="Get Started" icon="pi pi-arrow-right" className="p-button-primary" onClick={handleGetStarted} />
              </div>
            </Card>

            {/* Features Section */}
            <div className="features-section p-mt-4">
              <h3 className="section-title">Platform Features</h3>
              <div className="p-grid p-justify-center">
                <div className="p-col-12 p-md-4">
                  <Card className="feature-card p-shadow-3">
                    <i className="pi pi-cog pi-3x feature-icon" style={{ color: '#4CAF50' }}></i>
                    <h4>Customizable Settings</h4>
                    <p>
                      Tailor the platform to suit your specific needs with our flexible settings.
                    </p>
                  </Card>
                </div>
                <div className="p-col-12 p-md-4">
                  <Card className="feature-card p-shadow-3">
                    <i className="pi pi-chart-line pi-3x feature-icon" style={{ color: '#2196F3' }}></i>
                    <h4>Advanced Analytics</h4>
                    <p>
                      Gain insights into your data with our powerful analytics tools.
                    </p>
                  </Card>
                </div>
                <div className="p-col-12 p-md-4">
                  <Card className="feature-card p-shadow-3">
                    <i className="pi pi-users pi-3x feature-icon" style={{ color: '#FF9800' }}></i>
                    <h4>Collaborative Tools</h4>
                    <p>
                      Work seamlessly with your team using our integrated collaboration features.
                    </p>
                  </Card>
                </div>
              </div>
            </div>

            {/* Getting Started Section */}
            <div className="getting-started-section p-mt-4">
              <h3 className="section-title">Getting Started</h3>
              <div className="p-grid p-justify-center">
                <div className="p-col-12 p-md-6">
                  <Card className="getting-started-card p-shadow-3">
                    <h5>Step 1: Profile Setup</h5>
                    <p>
                      Complete your profile to get personalized recommendations and enhance your experience.
                    </p>
                    <Button label="Set Up Profile" icon="pi pi-user" className="p-button-secondary" onClick={handleProfileSetup} />
                  </Card>
                </div>
                <div className="p-col-12 p-md-6">
                  <Card className="getting-started-card p-shadow-3">
                    <h5>Step 2: Explore Features</h5>
                    <p>
                      Discover the various features we offer to maximize your productivity.
                    </p>
                    <Button label="Explore Features" icon="pi pi-search" className="p-button-secondary" onClick={handleExploreFeatures} />
                  </Card>
                </div>
              </div>
            </div>

            {/* Support Section */}
            <div className="support-section p-mt-4">
              <h3 className="section-title">Need Help?</h3>
              <p>
                Our support team is here to assist you. Visit our <a href="/support">Support Center</a> or contact us directly at <a href="mailto:support@yourplatform.com">support@yourplatform.com</a>.
              </p>
            </div>
          </div>
        </div>
    </div>
  );
};

export default CustomerLandingPage;
