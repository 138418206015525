import { Card } from "primereact/card";
import { Tree } from "primereact/tree";
import React, { useCallback, useEffect, useState, useTransition } from "react";
import api from "../../services/api";
import { isSessionExpiredError } from "../../utils/Common";
import { showToast } from "../../utils/toastService";
import BackupResourceUsage from "../../Components/BackupResourceUsage/BackupResourceUsage";

const ResourceUsage = () => {
    const [customerAccounts, setCustomerAccounts] = useState([]);
    const [isPending, startTransition] = useTransition();  // ✅ Proper use of useTransition
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedNodeKey, setSelectedNodeKey] = useState(null);

    const fetchCustomers = useCallback(async () => {
        startTransition(async () => {
            try {
                const response = await api.get("/customers?dropdown=true");
                setCustomerAccounts(response.data || []);
            } catch (error) {
                if (isSessionExpiredError(error)) {
                    showToast('warn', 'Session Expired', 'Your session has expired. Please log in again.');
                } else {
                    showToast("error", "Error", "Failed to fetch customers.");
                }
                console.error("Error fetching customers:", error);
            }
        });
    }, []);

    useEffect(() => {
        fetchCustomers();
    }, [fetchCustomers]);

    const treeCustomers = customerAccounts?.map((customer) => ({
        key: customer.id,
        label: customer.name,
        data: { id: customer.id },
    }));


    const handleNodeSelect = (event) => {
        const customerId = event.node.key;
        const customer = customerAccounts.find((c) => c.id === customerId);
        setSelectedUser(customer);  // Store full customer object
        setSelectedNodeKey({ [customerId]: true });
    };


    const renderCards = () => (
            <div className="card flex justify-content-center">
                <Card title={`Resource Usage for ${selectedUser.name}`}>
                    <BackupResourceUsage selectedUser={selectedUser} />
            </Card>
        </div>
    );

    const renderRightContent = () => {
        if (!selectedUser) {
            return (
                <Card className="p-4 text-center">
                    <h4 className="text-lg font-semibold">Select a Customer</h4>
                    <p className="text-gray-600">
                        Please select a customer from the left side to view the resource usage widgets.
                    </p>
                </Card>
            );
        }
        return renderCards();
    };

    return (
        <div className="card flex flex-wrap justify-content-center gap-5">
            <div className="d-flex justify-between">
                {/* Scrollable Tree Container */}
                <div className="w-full md:w-30rem overflow-auto" style={{ maxHeight: "600px", border: "1px solid #ddd", borderRadius: "8px", padding: "0.5rem" }}>
                    <Tree
                        loading={isPending}
                        selectionMode="single"
                        value={treeCustomers || []}
                        filter
                        filterMode="strict"
                        filterPlaceholder="Search Customer"
                        className="w-full"
                        selectionKeys={selectedNodeKey}
                        onSelectionChange={(e) => setSelectedNodeKey(e.value)}
                        onSelect={handleNodeSelect}
                    />
                </div>

                {/* Right Content */}
                <div className="w-100">{renderRightContent()}</div>
            </div>
        </div>

    );
};

export default ResourceUsage;
