import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tag } from 'primereact/tag';
import React, { useEffect, useState, useCallback, useTransition } from 'react';
import api from '../services/api';
import { showToast } from '../utils/toastService';
import '../assets/styles/CloudConnectTenants.css';
import { formatDateWithoutTimezone, isSessionExpiredError } from '../utils/Common';

const CloudConnectTenants = () => {
  const [tenants, setTenants] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [isPending, startTransition] = useTransition();

  const fetchTenants = useCallback(async () => {
    startTransition(async () => {
      try {
        const response = await api.get('/veeamcctenants');
        setTenants(response.data);
      } catch (error) {
        console.error('Error fetching tenants:', error);
        if (isSessionExpiredError(error)) {
          showToast('warn', 'Session Expired', 'Your session has expired. Please log in again.');
        } else {
          showToast('error', 'Error', 'Failed to fetch tenants.');
        }
      }
    });
  }, []);

  useEffect(() => {
    fetchTenants();
  }, [fetchTenants]);

  const resultBodyTemplate = (rowData) => {
    const severityMap = {
      Success: 'success',
      Warning: 'warning',
      Failed: 'danger',
    };
    return severityMap[rowData.lastResult] ? (
      <Tag value={rowData.lastResult} severity={severityMap[rowData.lastResult]} />
    ) : (
      rowData.lastResult
    );
  };

  const onRowSelect = (event) => {
    setSelectedTenant(event.data);
  };

  const hideDialog = () => {
    setSelectedTenant(null);
  };

  const tenantDetails = selectedTenant
    ? Object.entries(selectedTenant).filter(
      ([key]) => key !== 'passwordSalt' && key !== 'saltedPassword'
    )
    : [];

  const dialogFooter = (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button label="Close" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
    </div>
  );

  const formatLabel = (label) =>
    label.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());

  const formatValue = (value) => {
    if (typeof value === 'boolean') return value ? 'Yes' : 'No';
    if (!value) return 'N/A';
    if (typeof value === 'string' && Date.parse(value)) return formatDateWithoutTimezone(value);
    return value.toString();
  };

  return (
    <div className="cloud-connect-tenants">
      <h2>Cloud Connect Tenants</h2>
      {isPending ? (
        <div className="loading-container">
          <ProgressSpinner />
        </div>
      ) : (
          <DataTable
            value={tenants}
            paginator
            rows={20}
            scrollable
            selectionMode="single"
            onRowSelect={onRowSelect}
            dataKey="id"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} tenants"
            rowsPerPageOptions={[10, 20, 50]}
            className="p-datatable-customers"
            sortField="name"
            sortOrder={1}
            size="small"
          >
            <Column field="name" header="Name" sortable />
            <Column field="lastResult" header="Latest Result" body={resultBodyTemplate} sortable />
            <Column
              field="enabled"
              header="Enabled"
              body={(rowData) => (rowData.enabled ? 'Yes' : 'No')}
              sortable
            />
            <Column
              field="backupProtectionEnabled"
              header="Backup Protection"
              body={(rowData) => (rowData.backupProtectionEnabled ? 'Yes' : 'No')}
              sortable
            />
            <Column
              field="lastActive"
              header="Last Active"
              body={(rowData) =>
                rowData.lastActive ? formatDateWithoutTimezone(rowData.lastActive) : 'N/A'
              }
              sortable
            />
          </DataTable>
      )}

      <Dialog
        visible={!!selectedTenant}
        style={{ width: '50vw' }}
        header="Tenant Details"
        modal
        className="p-fluid"
        footer={dialogFooter}
        onHide={hideDialog}
      >
        {selectedTenant && (
          <div className="tenant-details-table">
            <table>
              <tbody>
                {tenantDetails.map(([key, value]) => (
                  <tr key={key}>
                    <td className="label-cell">{formatLabel(key)}</td>
                    <td className="value-cell">{formatValue(value)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default CloudConnectTenants;
