import { Card } from "primereact/card";
import { Tree } from "primereact/tree";
import React, { useEffect, useState } from "react";
import "../../../assets/styles/Dashboard.css";
import CustomerBackupStatusTable from "../../../Components/CustomerBackupStatusTable";
import CustomerVeeamBackupSessions from "../../../Components/CustomerVeeamBackupSessions";
import PhysicalServersTree from "../../../Components/PhysicalServersTree";
import ReplicaGrid from "../../../Components/ReplicaGrid";
import api from "../../../services/api";
import { isSessionExpiredError } from "../../../utils/Common";
import { showToast } from "../../../utils/toastService";

const CustomerBackUpDashboard = () => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(localStorage.getItem("selectedNodeKey") || null);
  const [selectedNodeKey, setSelectedNodeKey] = useState(localStorage.getItem("selectedNodeKey") || null);
  const [selectedCustomerName, setSelectedCustomerName] = useState(localStorage.getItem("nodeLabel") || null);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await api.get("/customers?dropdown=true");
        setCustomers(response.data || []);
      } catch (error) {
        if (isSessionExpiredError(error)) {
          showToast('warn', 'Session Expired', 'Your session has expired. Please log in again.');
        } else {
          showToast("error", "Error", "Failed to fetch customers.");
        }
        console.error("Error fetching customers:", error);
      }
    };
    fetchCustomers();
  }, []);

  const treeCustomers = customers.map((cust) => ({
    key: cust.id,
    label: cust.name,
    data: { customerId: cust.id },
  }));

  const handleNodeSelect = async (event) => {
    const customerId = event.node.key;
    localStorage.setItem("selectedNodeKey", customerId);
    setSelectedCustomer(customerId);
    setSelectedCustomerName(event.node.label);
    localStorage.setItem("nodeLabel", event.node.label);
    setSelectedNodeKey({ [customerId]: true });
  };

  const renderCards = () => (
    <div className="w-100">
      <div className="card flex justify-content-center">
        <Card>{<CustomerBackupStatusTable customerId={selectedCustomer} />}</Card>
      </div>
      <div className="card flex justify-content-center">
        <Card title="VBR Overview">
          {<PhysicalServersTree customerId={selectedCustomer} />}
        </Card>
      </div>
      <div className="card flex justify-content-center">
        <Card>{<ReplicaGrid customerId={selectedCustomer} />}</Card>
      </div>
      <div className="card flex justify-content-center">
        <Card>{<CustomerVeeamBackupSessions selectedCustomerName={selectedCustomerName} customerId={selectedCustomer} />}</Card>
      </div>
    </div>
  );

  const renderRightContent = () => {
    if (!selectedCustomer) {
      return (
        <Card className="p-4 text-center">
          <h4 className="text-lg font-semibold">Select a Customer</h4>
          <p className="text-gray-600">
            Please select a customer from the left side to view the dashboard widgets.
          </p>
        </Card>
      );
    }
    return renderCards();
  };

  return (
    <div className="card flex flex-wrap justify-content-center gap-5">
      <div className="d-flex justify-between">
        <div className="w-full md:w-30rem overflow-auto" style={{ maxHeight: "600px", border: "1px solid #ddd", borderRadius: "8px", padding: "0.5rem" }}>
        <Tree
          selectionMode="single"
          value={treeCustomers}
          filter
          filterMode="strict"
          filterPlaceholder="Search Customer"
            className="w-full"
          selectionKeys={selectedNodeKey}
          onSelectionChange={(e) => setSelectedNodeKey(e.value)} // Update selected node
          onSelect={handleNodeSelect}
        />
        </div>
        <div className="w-100" style={{ padding: "0 1rem" }}>{renderRightContent()}</div>
      </div>
    </div>
  );
};

export default CustomerBackUpDashboard;
