import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { useEffect, useState } from "react";

const FiltersPanel = ({ visible, onHide, onApply, appliedFilters }) => {
    const [filters, setFilters] = useState({
        name: "",
        status: null,
        type: null,
    });

    const statusOptions = ["Stopped", "Running", "Postprocessing", "Failed", "Working"];

    useEffect(() => {
        if (visible) {
            setFilters(
                appliedFilters || { name: "", status: null, type: null }
            );
        }
    }, [visible, appliedFilters]);
    const handleApplyFilters = () => {
        onApply(filters);
        onHide();
    };
    return (
        <Sidebar visible={visible} onHide={onHide} position="right" style={{ width: "20vw" }}>
            <h3 className="mb-4">Filter Backup Sessions</h3>
            <div className="p-fluid">
                <div className="mb-4">
                    <label>Name</label>
                    <InputText
                        placeholder="Please enter job name"
                        value={filters.name || ""}
                        onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                    />
                </div>
                <div className="mb-4">
                    <label>Status</label>
                    <Dropdown
                        value={filters.status}
                        options={statusOptions}
                        onChange={(e) => setFilters({ ...filters, status: e.value })}
                        placeholder="Select Status"
                    />
                </div>
                <div className="mb-4">
                    <label>Job Type</label>
                    <InputText
                        placeholder="Please enter job name"
                        value={filters.type || ""}
                        onChange={(e) => setFilters({ ...filters, type: e.target.value })}
                    />
                </div>
                <Button
                    label="Apply Filters"
                    icon="pi pi-check"
                    onClick={handleApplyFilters}
                    className="mt-4"
                />
            </div>
        </Sidebar>
    );
};

export default FiltersPanel;
