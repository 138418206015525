import { useForm, Controller } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

const UserAdministrationForm = ({ userDialog, hideDialog, onSubmit, rolesDropdownValues, customerAccounts, partnerAccounts, defaultValues }) => {
    const getDefaultValue = (options, id, key) =>
        options?.find(option => option.value === id)?.[key] || null;
    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        defaultValues: defaultValues
            ? {
                id: defaultValues.id,
                firstName: defaultValues.firstName || "",
                lastName: defaultValues.lastName || "",
                email: defaultValues.email || "",
                password: defaultValues.password,
                role: getDefaultValue(rolesDropdownValues, defaultValues.role, "value"), // Only label
                customerAccount: getDefaultValue(customerAccounts, defaultValues.customerId, "value"), // Only value
                partnerAccount: getDefaultValue(partnerAccounts, defaultValues.partnerAccountId, "value") // Only label
            }
            : {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                role: null,
                customerAccount: null,
                partnerAccount: null
            }
    });
    const handleClose = () => {
        reset();
        hideDialog();
    };

    return (
        <Dialog visible={userDialog} style={{ width: '450px' }} header="User Details" modal className="p-fluid" footer={
            <div>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={handleClose} />
                <Button label="Save" icon="pi pi-check" className="p-button-primary" onClick={handleSubmit(onSubmit)} />
            </div>
        } onHide={handleClose}>

            <div className="p-field">
                <label htmlFor="firstName">First Name <span style={{ color: "red" }}>*</span></label>
                <Controller
                    name="firstName"
                    control={control}
                    rules={{
                        required: "First name is required",
                        pattern: {
                            value: /^[A-Za-z\s]+$/,
                            message: "Only letters and spaces are allowed",
                        },
                    }}
                    render={({ field }) => (
                        <InputText
                            placeholder="Please enter first name"
                            id="firstName"
                            {...field}
                            className={errors.firstName ? "p-invalid" : ""}
                        />
                    )}
                />
                {errors.firstName && <small className="p-error">{errors.firstName.message}</small>}
            </div>

            <div className="p-field">
                <label htmlFor="lastName">Last Name <span style={{ color: "red" }}>*</span></label>
                <Controller
                    name="lastName"
                    control={control}
                    rules={{
                        required: "Last name is required",
                        pattern: {
                            value: /^[A-Za-z\s]+$/,
                            message: "Only letters and spaces are allowed",
                        },
                    }}
                    render={({ field }) => (
                        <InputText
                            placeholder="Please enter last name"
                            id="lastName"
                            {...field}
                            className={errors.lastName ? "p-invalid" : ""}
                        />
                    )}
                />
                {errors.lastName && <small className="p-error">{errors.lastName.message}</small>}
            </div>


            <div className="p-field">
                <label htmlFor="email">Email <span style={{ color: "red" }}>*</span></label>
                <Controller
                    name="email"
                    control={control}
                    rules={{
                        required: "Email is required",
                        pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: "Invalid email address",
                        },
                    }}
                    render={({ field }) => (
                        <InputText
                            id="email"
                            placeholder="Please enter email address"
                            {...field}
                            className={errors.email ? "p-invalid" : ""}
                        />
                    )}
                />
                {errors.email && <small className="p-error">{errors.email.message}</small>}
            </div>


            <div className="p-field">
                <label htmlFor="password">Password <span style={{ color: "red" }}>*</span></label>
                <Controller
                    name="password"
                    control={control}
                    rules={{ required: "Password is required" }}
                    render={({ field }) => <InputText id="password" placeholder="Please enter password" type="password" {...field} className={errors.password ? 'p-invalid' : ''} />}
                />
                {errors.password && <small className="p-error">{errors.password.message}</small>}
            </div>

            <div className="p-field">
                <label htmlFor="role">Role</label>
                <Controller
                    name="role"
                    control={control}
                    rules={{ required: "Role is required" }}
                    render={({ field }) => (
                        <Dropdown id="role" {...field} options={rolesDropdownValues} optionLabel="label" placeholder="Select Role" className={errors.role ? 'p-invalid' : ''} />
                    )}
                />
                {errors.role && <small className="p-error">{errors.role.message}</small>}
            </div>

            <div className="p-field">
                <label htmlFor="customerAccount">Customer Account</label>
                <Controller
                    name="customerAccount"
                    rules={{ required: "Please associat customer account." }}
                    control={control}
                    render={({ field }) => (
                        <Dropdown className={errors.customerAccount ? 'p-invalid' : ''} filterInputAutoFocus filter filterPlaceholder="Enter customer name" id="customerAccount" {...field} options={customerAccounts} optionLabel="label" placeholder="Select Customer Account" />
                    )}
                />
                {errors.customerAccount && <small className="p-error">{errors.customerAccount.message}</small>}
            </div>

            <div className="p-field">
                <label htmlFor="partnerAccount">Partner Account</label>
                <Controller
                    name="partnerAccount"
                    control={control}
                    render={({ field }) => (
                        <Dropdown filterInputAutoFocus filter filterPlaceholder="Enter partner name" id="partnerAccount" {...field} options={partnerAccounts} optionLabel="label" placeholder="Select Partner Account" />
                    )}
                />
            </div>

        </Dialog>
    );
};

export default UserAdministrationForm;