// src/Components/admin/CustomerBackupSessions.jsx

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tag } from 'primereact/tag';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useTransition, useCallback } from 'react';
import api from '../services/api';
import { showToast } from '../utils/toastService';
import '../assets/styles/BackupSessions.css';
import { formatDateWithoutTimezone, isSessionExpiredError } from '../utils/Common';

const FailedVeeamBackupSessions = ({ days }) => {
  const [backupSessions, setBackupSessions] = useState([]);
  const [isPending, startTransition] = useTransition();

  const fetchBackupSessions = useCallback(async () => {
    startTransition(async () => {
      try {
        const response = await api.get(`/veeambackupsessions/result/failed/days/${days}`);
        setBackupSessions(response.data);
      } catch (error) {
        if (isSessionExpiredError(error)) {
          showToast('warn', 'Session Expired', 'Your session has expired. Please log in again.');
        } else {
          showToast('error', 'Error', 'Failed to fetch backup sessions');
        }
      }
    });
  }, [days]);

  useEffect(() => {
    fetchBackupSessions();
  }, [days, fetchBackupSessions]);


  // Template for status column with colored tags
  const statusBodyTemplate = (rowData) => {
    let severity;
    switch (rowData.status) {
      case 'Stopped':
      case 'Running':
        severity = 'success';
        break;
      case 'Postprocessing':
        severity = 'success';
        break;
      case 'Failed':
        severity = 'danger';
        break;
      default:
        severity = null;
    }
    return <Tag value={rowData.status} severity={severity} />;
  };

  // Template for result column with colored tags
  const resultBodyTemplate = (rowData) => {
    let severity;
    switch (rowData.result) {
      case 'Success':
        severity = 'success';
        break;
      case 'Warning':
        severity = 'warning';
        break;
      case 'Failed':
        severity = 'danger';
        break;
      default:
        severity = null;
    }
    return <Tag value={rowData.result} severity={severity} />;
  };

  // Template for formatting date fields
  const dateBodyTemplate = (rowData, field) => {
    return formatDateWithoutTimezone(rowData[field]);
  };

  // Template for calculating and displaying duration
  const durationBodyTemplate = (rowData) => {
    if (rowData.jobStartTime && rowData.jobEndTime) {
      const duration = new Date(rowData.jobEndTime) - new Date(rowData.jobStartTime);
      const minutes = Math.floor(duration / 60000);
      const seconds = Math.floor((duration % 60000) / 1000);
      return `${minutes} mins ${seconds} secs`;
    } else if (rowData.jobStartTime && !rowData.jobEndTime) {
      return 'In Progress';
    } else {
      return 'ERROR: Data Incomplete';
    }
  };

  return (
    <div className="backup-sessions">
      <h2>Failed Backup Sessions in the last {days} days</h2>
      {isPending ? (
        <div className="loading-container">
          <ProgressSpinner />
        </div>
      ) : (
      <DataTable
        value={backupSessions}
        paginator
        rows={20}
            scrollable
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} backup sessions"
        rowsPerPageOptions={[10, 20, 50]}
        className="p-datatable-customers"
        sortField="jobStartTime"
        sortOrder={-1}
        size={"small"}
      >
        {/* <Column field="id" header="Session ID" sortable></Column> */}
        <Column field="veeamJob.name" header="Job Name" sortable></Column>
        <Column field="status" header="Status" body={statusBodyTemplate} sortable></Column>
        <Column field="result" header="Result" body={resultBodyTemplate} sortable></Column>
        <Column
          field="jobStartTime"
          header="Start Time"
          body={(rowData) => dateBodyTemplate(rowData, 'jobStartTime')}
          sortable
        ></Column>
        <Column
          field="jobEndTime"
          header="End Time"
          body={(rowData) => dateBodyTemplate(rowData, 'jobEndTime')}
          sortable
        ></Column>
        <Column header="Duration" body={durationBodyTemplate} sortable></Column>
      </DataTable>
      )}
    </div>
  );
};

FailedVeeamBackupSessions.propTypes = {
  days: PropTypes.object.isRequired,
};

export default FailedVeeamBackupSessions;
