import axios from "axios";
import { clearAuth, getToken } from "../utils/helpers";

const api = axios.create({
  baseURL: 'https://api.skydatavault.com', // Adjust the baseURL to match your backend API URL
  headers: {
    "Content-Type": "application/json",
  },
});

// 🔹 Request Interceptor - Attach Token
api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 419 || error?.response?.status === 403) {
      setTimeout(() => {
        clearAuth();
        window.location.href = "/login";
      }, 2000);
    }

    return Promise.reject(error);
  }
);

export default api;
