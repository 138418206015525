import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { useEffect, useState } from "react";

const FiltersPanel = ({ customerAccounts, visible, onHide, onApply, appliedFilters }) => {
    const [filters, setFilters] = useState({
        name: "",
        status: null,
        customerName: null
    });

    useEffect(() => {
        if (visible) {
            setFilters(
                appliedFilters || { name: "", status: null, customerName: null }
            );
        }
    }, [visible, appliedFilters]);

    const handleApplyFilters = () => {
        onApply(filters);
        onHide();
    };

    return (
        <Sidebar visible={visible} onHide={onHide} position="right" style={{ width: "20vw" }}>
            <h3 className="mb-4">Filter Companies Account</h3>
            <div className="p-fluid">
                <div className="mb-4">
                    <label>Company Name</label>
                    <InputText
                        placeholder="Please enter company name"
                        value={filters.name || ""}
                        onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                    />
                </div>

                <div className="mb-4">
                    <label>Status</label>
                    <Dropdown
                        value={filters.status}
                        options={[
                            { label: "Active", value: "Active" },
                            { label: "Inactive", value: "Inactive" },
                            { label: "Pending", value: "Pending" },
                        ]}
                        onChange={(e) => setFilters({ ...filters, status: e.value })}
                        placeholder="Select Status"
                        showClear
                    />
                </div>

                <div className="mb-4">
                    <label>Associated Customer Account</label>
                    <Dropdown
                        filter
                        filterInputAutoFocus
                        optionValue="name"
                        optionLabel="name"
                        filterPlaceholder="Enter customer name"
                        value={filters.customerName}
                        options={customerAccounts}
                        onChange={(e) => setFilters({ ...filters, customerName: e.value })}
                        placeholder="Select Customer"
                        showClear
                    />
                </div>

                <Button
                    label="Apply Filters"
                    icon="pi pi-check"
                    onClick={handleApplyFilters}
                    className="mt-4"
                />
            </div>
        </Sidebar>
    );
};

export default FiltersPanel;
