import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";
import { Controller, useForm } from "react-hook-form";

const CustomerAdministrationForm = ({ customerDialog, hideDialog, onSubmit, partnerAccounts, defaultValues, companyList }) => {
    const getDefaultValue = (options, id, key) =>
        options?.find(option => option.value === id)?.[key] || null;
    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        defaultValues: defaultValues
            ? {
                id: defaultValues.id,
                name: defaultValues.name || "",
                description: defaultValues.description || "",
                status: defaultValues.status || "",
                partnerAccount: getDefaultValue(partnerAccounts, defaultValues.partnerAccountId, "value"),
                veeamCompanyIds: defaultValues.veeamCompanies?.map(company => company.instanceUid) || []
            }
            : {
                name: "",
                description: "",
                status: "",
                partnerAccount: null,
                veeamCompanyIds: []
            }
    });
    const handleClose = () => {
        reset();
        hideDialog();
    };

    const accountStatusOptions = ['Active', 'Disabled']
    return (
        <Dialog visible={customerDialog} style={{ width: '450px' }} header="Customer Details" modal className="p-fluid" footer={
            <div>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={handleClose} />
                <Button label="Save" icon="pi pi-check" className="p-button-primary" onClick={handleSubmit(onSubmit)} />
            </div>
        } onHide={handleClose}>

            <div className="field">
                <label htmlFor="name">Customer Account Name <span style={{ color: "red" }}>*</span></label>
                <Controller
                    name="name"
                    control={control}
                    rules={{
                        required: "Account name is required",
                        pattern: {
                            value: /^[A-Za-z\s]+$/,
                            message: "Only letters and spaces are allowed",
                        },
                    }}
                    render={({ field }) => (
                        <InputText
                            placeholder="Please enter account name"
                            id="name"
                            {...field}
                            className={errors.name ? "p-invalid" : ""}
                        />
                    )}
                />
                {errors.name && <small className="p-error">{errors.name.message}</small>}
            </div>


            <div className="p-field">
                <label htmlFor="status">Account Status <span style={{ color: "red" }}>*</span></label>
                <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                        <Dropdown id="partnerAccount" {...field} options={accountStatusOptions} optionLabel="label"
                            placeholder="Select Account Status" />
                    )}
                />
            </div>
            <div className="field">
                <label htmlFor="description">Account Description</label>
                <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                        <InputTextarea
                            id="description"
                            placeholder="Please enter account description"
                            {...field}
                        />
                    )}
                />
            </div>

            <div className="p-field">
                <label htmlFor="partnerAccount">Partner Account</label>
                <Controller
                    name="partnerAccount"
                    control={control}
                    render={({ field }) => (
                        <Dropdown filterInputAutoFocus filter filterPlaceholder="Enter partner name" id="partnerAccount" {...field} options={partnerAccounts} optionLabel="label" placeholder="Select Partner Account" />
                    )}
                />
            </div>
            <div className="p-field">
                <label htmlFor="veeamCompanyIds">Select Companies</label>
                <Controller
                    name="veeamCompanyIds"
                    control={control}
                    rules={{
                        required: "Please select associated company",
                    }}
                    render={({ field }) => (
                        <MultiSelect
                            id="veeamCompanyIds"
                            value={field.value}
                            onChange={(e) => field.onChange(e.value)}
                            options={companyList.map(company => ({
                                label: company.name,
                                value: company.instanceUid
                            }))}
                            placeholder="Select Companies"
                            filter
                            display="chip"
                            className={errors.veeamCompanyIds ? "p-invalid" : ""}
                        />
                    )}
                />
                {errors.veeamCompanyIds && <small className="p-error">{errors.veeamCompanyIds.message}</small>}
            </div>

        </Dialog>
    );
};

export default CustomerAdministrationForm;