import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { useEffect, useState } from "react";

const FiltersPanel = ({ visible, onHide, onApply, appliedFilters, partnerAccounts }) => {
    const [filters, setFilters] = useState({
        name: "",
        status: null,
        accountIdentifier: null,
        updatedAt: null,
        partnerId: null,
    });

    useEffect(() => {
        if (visible) {
            setFilters(
                appliedFilters || { name: "", status: null, accountIdentifier: null, updatedAt: null, partnerId: null, }
            );
        }
    }, [visible, appliedFilters]);
    const handleApplyFilters = () => {
        onApply(filters);
        onHide();
    };
    const handleDateChange = (key, date) => {
        if (date) {
            const localDate = new Date(date);
            localDate.setHours(12, 0, 0, 0);
            setFilters({ ...filters, [key]: localDate });
        } else {
            setFilters({ ...filters, [key]: null });
        }
    };
    const accountStatusOptions = ['Active', 'Disabled']
    return (
        <Sidebar visible={visible} onHide={onHide} position="right" style={{ width: "20vw" }}>
            <h3 className="mb-4">Filter Customer Account</h3>
            <div className="p-fluid">
                <div className="mb-4">
                    <label>Customer Name</label>
                    <InputText
                        placeholder="Please enter customer name"
                        value={filters.name || ""}
                        onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                    />
                </div>

                <div className="mb-4">
                    <label>Status</label>
                    <Dropdown
                        value={filters.status}
                        options={accountStatusOptions}
                        onChange={(e) => setFilters({ ...filters, status: e.value })}
                        placeholder="Select Account Status"
                    />
                </div>
                <div className="mb-4">
                    <label>Identifier</label>
                    <InputText
                        value={filters.accountIdentifier || ""}
                        onChange={(e) => setFilters({ ...filters, accountIdentifier: e.target.value })}
                        placeholder="Please enter account indentifier"
                    />
                </div>
                <div className="mb-4">
                    <label>Partner</label>
                    <Dropdown
                        filter
                        filterInputAutoFocus
                        filterPlaceholder="Enter partner name"
                        value={filters.partnerId || ""}
                        options={partnerAccounts}
                        onChange={(e) => setFilters({ ...filters, partnerId: e.value })}
                        placeholder="Select Partner"
                        showClear
                    />
                </div>
                <div className="mb-4">
                    <label>UpdateAt Date</label>
                    <Calendar
                        value={filters.updatedAt || ""}
                        onChange={(e) => handleDateChange("updatedAt", e.value)}
                        placeholder="Select date"
                        dateFormat="yy-mm-dd"
                        showIcon
                    />
                </div>
                <Button
                    label="Apply Filters"
                    icon="pi pi-check"
                    onClick={handleApplyFilters}
                    className="mt-4"
                />
            </div>
        </Sidebar>
    );
};

export default FiltersPanel;
