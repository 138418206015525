// src/Components/Dashboard/dashboardData.js

// KPI Cards Data
export const kpiData = [
  { label: 'Total Users', value: 1024, icon: 'pi pi-users', color: '#42A5F5' },
  { label: 'Active Backups', value: 256, icon: 'pi pi-cloud-upload', color: '#66BB6A' },
  { label: 'Storage Used', value: '1.2 TB', icon: 'pi pi-hdd', color: '#FFA726' },
  { label: 'Pending Tasks', value: 12, icon: 'pi pi-clock', color: '#AB47BC' },
];

// Storage Usage Chart Data
export const storageUsageData = {
  labels: ['Client A', 'Client B', 'Client C', 'Client D'],
  datasets: [
    {
      label: 'Storage Usage (GB)',
      backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726', '#AB47BC'],
      data: [500, 700, 300, 450],
    },
  ],
};

// Recent Backup Sessions Data
export const recentBackupSessions = [
  {
    id: 1,
    client: 'Client A',
    status: 'Successful',
    date: '2024-10-25 10:30 AM',
  },
  {
    id: 2,
    client: 'Client B',
    status: 'Failed',
    date: '2024-10-25 09:15 AM',
  },
  {
    id: 3,
    client: 'Client C',
    status: 'Successful',
    date: '2024-10-24 04:45 PM',
  },
  {
    id: 4,
    client: 'Client D',
    status: 'In Progress',
    date: '2024-10-24 02:20 PM',
  },
  {
    id: 5,
    client: 'Client A',
    status: 'Successful',
    date: '2024-10-23 11:00 AM',
  },
];

// Additional Metrics Data
export const additionalMetrics = [
  {
    label: 'Backup Frequency',
    value: 'Daily',
    icon: 'pi pi-calendar',
    color: '#9C27B0',
  },
  {
    label: 'Average Backup Time',
    value: '45 mins',
    icon: 'pi pi-clock',
    color: '#009688',
  },
  {
    label: 'Data Transferred',
    value: '5 TB',
    icon: 'pi pi-database',
    color: '#00BCD4',
  },
  {
    label: 'Alerts',
    value: 3,
    icon: 'pi pi-exclamation-triangle',
    color: '#E53935',
  },
];

// Line Chart Data
export const fakeLineChartData = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
  datasets: [
    {
      label: 'Storage Growth',
      data: [50, 100, 150, 200, 250, 300, 350],
      fill: false,
      borderColor: '#42A5F5',
      tension: 0.4,
    },
  ],
};

// Pie Chart Data
export const backupTypesData = {
  labels: ['Full', 'Incremental', 'Differential'],
  datasets: [
    {
      data: [300, 50, 100],
      backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726'],
      hoverBackgroundColor: ['#64B5F6', '#81C784', '#FFB74D'],
    },
  ],
};
