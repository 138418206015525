import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import api from "../../services/api";
import { showToast } from "../../utils/toastService";
import { isSessionExpiredError } from "../../utils/Common";

const RecordForm = ({ isEdit, visible, setVisible, tableName, initialData, onSuccess }) => {
    const [formData, setFormData] = useState(initialData || {});
    const [jsonErrors, setJsonErrors] = useState({});

    useEffect(() => {
        setFormData(initialData || {});
    }, [initialData]);

    const handleChange = (e, key) => {
        if (typeof formData[key] === "object") {
            try {
                setFormData({ ...formData, [key]: JSON.parse(e.target.value) });
                setJsonErrors((prev) => ({ ...prev, [key]: false }));
            } catch {
                setJsonErrors((prev) => ({ ...prev, [key]: true }));
            }
        } else {
            setFormData({ ...formData, [key]: e.target.value });
        }
    };

    const handleSubmit = async () => {
        if (Object.values(jsonErrors).some((error) => error)) {
            showToast("error", "Validation Error", "Please fix JSON errors before saving.");
            return;
        }

        try {
            const endpoint = isEdit ? `/db/tables/${tableName}` : `/db/tables/${tableName}/add`;
            const method = isEdit ? "put" : "post";

            await api[method](endpoint, {
                primaryKey: isEdit ? { id: initialData.id } : null,
                data: formData,
            });

            showToast("success", "Success", isEdit ? "Record updated!" : "New record added!");
            onSuccess();
            setVisible(false);
        } catch (error) {
            console.error("Error saving record:", error);
            if (isSessionExpiredError(error)) {
                showToast("error", "Session Expired", "Your session has expired. Please log in again.");
            } else {
                showToast("error", "Error", "Failed to save record.");
            }
        }
    };

    return (
        <Dialog
            header={isEdit ? "Edit Record" : "Add New Record"}
            visible={visible}
            style={{ width: "450px" }}
            modal
            onHide={() => setVisible(false)}
            footer={
                <div>
                    <Button label="Cancel" icon="pi pi-times" onClick={() => setVisible(false)} />
                    <Button label="Save" icon="pi pi-check" onClick={handleSubmit} />
                </div>
            }
        >
            {Object.keys(formData).map((key) => (
                <div key={key} className="mb-3">
                    <label className="block font-bold">{key}</label>
                    {typeof formData[key] === "object" ? (
                        <>
                            <textarea
                                name={key}
                                value={JSON.stringify(formData[key], null, 2)}
                                onChange={(e) => handleChange(e, key)}
                                rows={4}
                                style={{ width: "100%", border: jsonErrors[key] ? "2px solid red" : "1px solid #ccc" }}
                            />
                            {jsonErrors[key] && <span style={{ color: "red" }}>Invalid JSON format</span>}
                        </>
                    ) : (
                        <InputText name={key} value={formData[key] || ""} onChange={(e) => handleChange(e, key)} className="w-full" />
                    )}
                </div>
            ))}
        </Dialog>
    );
};

export default RecordForm;
