import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tag } from 'primereact/tag';
import { Toolbar } from 'primereact/toolbar';
import React, { useCallback, useEffect, useState, useTransition } from 'react';
import api from '../../../services/api';
import DataTableSkeleton from '../../../Skeletons/DataTable';
import { isSessionExpiredError } from '../../../utils/Common';
import { showToast } from '../../../utils/toastService';
import FiltersPanel from '../../Filters/VeeamCompanies/FiltersPanel';

const VeeamCompanyAdministration = () => {
    const [isLoading, setIsloading] = useTransition();
    const [companies, setCompanies] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [customerAccounts, setCustomerAccounts] = useState([]);
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState({});
    const [pagination, setPagination] = useState({
        page: 1,
        rows: 10,
        sortField: "",
        sortOrder: "desc",
    });

    const fetchCompanies = useCallback(async () => {
        setIsloading(async () => {
            try {
                const params = {
                    page: pagination.page,
                    limit: pagination.rows,
                    sortField: pagination.sortField,
                    sortOrder: pagination.sortOrder,
                    ...appliedFilters,
                };
                const response = await api.get("/veeam-companies", { params });
                setCompanies(response.data.data || []);
                setTotalRecords(response.data?.pagination.totalRecords);
            } catch (error) {
                if (isSessionExpiredError(error)) {
                    showToast('warn', 'Session Expired', 'Your session has expired. Please log in again.');
                } else {
                    showToast("error", "Error", "Failed to fetch companies.");
                }
                console.error("Error fetching companies:", error);
            }
        });
    }, [pagination, appliedFilters]);

    const fetchCustomerAccounts = useCallback(async () => {
        setIsloading(async () => {
            try {
                const response = await api.get('/customers?dropdown=true',);
                setCustomerAccounts(response.data || []);
            } catch (error) {
                if (isSessionExpiredError(error)) {
                    showToast('warn', 'Session Expired', 'Your session has expired. Please log in again.');
                } else {
                    showToast("error", "Error", "Failed to fetch customer accounts.");
                }
            }
        });
    }, []);

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    useEffect(() => {
        fetchCustomerAccounts();
    }, [fetchCustomerAccounts]);

    const clearFilter = (filterKey) => {
        setAppliedFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters };
            delete updatedFilters[filterKey];
            return updatedFilters;
        });
    };

    const clearAllFilters = () => {
        setPagination({
            page: 1,
            rows: 10,
            sortField: "",
            sortOrder: "desc",
        });
        setAppliedFilters({});
    };

    const applyFilters = (filters) => {
        setAppliedFilters(filters);
    };

    const onPageChange = (event) => {
        setPagination((prev) => ({
            ...prev,
            page: event.page + 1,
            rows: event.rows,
        }));
    };

    const onSort = (event) => {
        setPagination((prev) => ({
            ...prev,
            sortField: event.sortField,
            sortOrder: event.sortOrder === 1 ? "asc" : "desc",
        }));
    };


    const rightToolbarTemplate = () => {
        const filterLabels = {
            name: "Name",
            status: "Status",
            customerName: "Customer Name",
        };

        return (
            <div className="flex justify-between items-center mb-3">
                <div className="flex gap-2">
                    {Object.entries(appliedFilters).map(([key, value]) => {
                        if (!value || !filterLabels[key]) return null;
                        let displayValue = value;
                        return (
                            <Chip
                                key={key}
                                label={`${filterLabels[key]}: ${displayValue}`}
                                removable
                                onRemove={() => clearFilter(key)}
                            />
                        );
                    })}
                </div>
                <div className="flex gap-2">
                    {Object.keys(appliedFilters).length > 0 && (
                        <Button icon="pi pi-times" onClick={clearAllFilters} className="ml-2 p-button-outlined" />
                    )}
                    <Button icon="pi pi-filter" onClick={() => setIsFilterVisible(true)} className="p-button-outlined" />
                </div>
            </div>
        );
    };

    const CustomerAssociationBodyTemplate = (rowData) => {
        if (!rowData.customers || rowData.customers.length === 0) {
            return 'None';
        }
        return rowData.customers
            .map(customer => customer.customerAccount?.name)
            .filter(name => name)
            .join(', ');
    };

    const accountStatusBodyTemplate = (rowData) => {
        let severity = 'info';
        switch (rowData.status) {
            case 'Active': severity = 'success'; break;
            case 'Disabled': severity = 'danger'; break;
            default: severity = 'info';
        }
        return <Tag value={rowData.status} severity={severity} />;
    };
    return (
        <div className="datatable-crud-demo">
            <div className="card">
                {isLoading ? (<DataTableSkeleton />) : (
                    <>
                        <Toolbar className="p-mb-4" start={<h4>Company Administration</h4>} end={rightToolbarTemplate}></Toolbar>
                        <FiltersPanel customerAccounts={customerAccounts} appliedFilters={appliedFilters} visible={isFilterVisible}
                            onHide={() => setIsFilterVisible(false)} onApply={applyFilters} />
                        <DataTable
                            value={companies}
                            lazy
                            paginator
                            first={(pagination.page - 1) * pagination.rows}
                            rows={pagination.rows}
                            totalRecords={totalRecords}
                            loading={isLoading}
                            onPage={onPageChange}
                            onSort={onSort}
                            sortField={pagination.sortField}
                            sortOrder={pagination.sortOrder === "asc" ? 1 : -1}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} customers"
                            rowsPerPageOptions={[10, 20, 50]}
                            className="p-datatable-gridlines">
                            <Column field="name" header="Company Name" sortable />
                            <Column field="status" header="Status" body={accountStatusBodyTemplate} sortable />
                            <Column header="Associated Customer" body={CustomerAssociationBodyTemplate} />
                        </DataTable>

                    </>
                )}
            </div>
        </div>
    )
}

export default VeeamCompanyAdministration;