import React from "react";
import CustomerAdministration from "../../../Components/AdminManagement/CustomerDashboard/CustomerAdministration";

const CustomerManagement = () => {
    return (
        <div className="p-grid p-nogutter user-admin-panel">
            <div className="p-col content-column p-p-3">
                <div className="content-area">
                    <CustomerAdministration />
                </div>
            </div>
        </div>
    );
};

export default CustomerManagement;
