import React from "react";
import UserAdministration from "../../../Components/AdminManagement/UserDashboard/UserAdministration";
import './UserAdminPanel.css'; // We'll create this file for page-level styling as well

const UserManagement = () => {
  return (
    <div className="p-grid p-nogutter user-admin-panel">
      <div className="p-col content-column p-p-3">
        <div className="content-area">
          <UserAdministration />
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
