import { Card } from "primereact/card";
import { Skeleton } from "primereact/skeleton";

const ResourceUsageSkeleton = () => {
    return (
        <div className="p-4">
            <div className="grid grid-cols-3 gap-4">
                <Card>
                    <div className="flex items-center">
                        <Skeleton size="25rem" className="items-center" />
                    </div>
                </Card>
                <Card>
                    <div>
                        <div className="d-flex gap-4">
                            <Skeleton height="1rem" width="5rem" className="mb-2" borderRadius="16px"></Skeleton>
                            <Skeleton height="1rem" width="5rem" className="mb-2" borderRadius="16px"></Skeleton>
                            <Skeleton height="1rem" width="5rem" className="mb-2" borderRadius="16px"></Skeleton>
                        </div>
                        <Skeleton size="25rem" className="items-center" />
                    </div>
                </Card>

                <Card className="p-4 shadow-md border border-gray-200 rounded-lg">
                    <div className="flex flex-col gap-3">
                        {/* Server Backups */}
                        <div className="flex items-center gap-2">
                            <i className="pi pi-server text-blue-500 text-lg"></i>
                            <Skeleton width="2rem" height="1.5rem" /> :

                        </div>

                        {/* Workstation Backups */}
                        <div className="flex items-center gap-2">
                            <i className="pi pi-desktop text-green-500 text-lg"></i>
                            <Skeleton width="2rem" height="1.5rem" />
                        </div>

                        {/* VM Backups */}
                        <div className="flex items-center gap-2">
                            <i className="pi pi-database text-yellow-500 text-lg"></i>
                            <Skeleton width="2rem" height="1.5rem" />
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default ResourceUsageSkeleton;
