import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tag } from "primereact/tag";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/styles/BackupSessions.css";
import api from "../services/api";
import { Toolbar } from 'primereact/toolbar';
import DataTableSkeleton from "../Skeletons/DataTable";
import { formatDateOnly, formatDateWithoutTimezone, isSessionExpiredError } from "../utils/Common";
import { showToast } from "../utils/toastService";
import FiltersPanel from "./Filters/CustomerVeeamBackupSessionFilter/FiltersPanel";

const CustomerVeeamBackupSessions = ({ customerId, selectedCustomerName }) => {
  const navigate = useNavigate();
  const [backupSessions, setBackupSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,      // API page (starts from 1)
    rows: 10,     // Rows per page (default 10)
    sortField: "",
    sortOrder: "desc",  // 'asc' or 'desc'
  });

  const fetchBackupSessions = useCallback(async () => {
    if (!customerId) return;

    setLoading(true);
    try {
      const params = {
        page: pagination.page,
        limit: pagination.rows,
        sortField: pagination.sortField === "veeamJob.name" ? "jobName" : pagination.sortField,
        sortOrder: pagination.sortOrder,
        ...appliedFilters,
      };

      const response = await api.get(`/veeambackupsessions/tenant/${customerId}`, { params });
      setBackupSessions(response.data.data);
      setTotalRecords(response.data?.pagination.totalRecords);
    } catch (error) {
      console.error("Error fetching customer backup status:", error);
      if (isSessionExpiredError(error)) {
        showToast("warn", "Session Expired", "Your session has expired. Please log in again.");
      } else {
        showToast("error", "Error", "Failed to fetch backup sessions.");
      }
    } finally {
      setLoading(false);
    }
  }, [customerId, pagination, appliedFilters]);

  useEffect(() => {
    fetchBackupSessions();
  }, [fetchBackupSessions]);

  const clearFilter = (filterKey) => {
    setAppliedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      delete updatedFilters[filterKey];
      return updatedFilters;
    });
  };

  const clearAllFilters = () => {
    setAppliedFilters({});
  };

  const applyFilters = (filters) => {
    setAppliedFilters(filters);
  };

  const statusBodyTemplate = (rowData) => {
    const statusMap = {
      Stopped: "success",
      Running: "success",
      Postprocessing: "success",
      Failed: "danger",
    };

    return <Tag value={rowData.status} severity={statusMap[rowData.status] || null} />;
  };

  const resultBodyTemplate = (rowData) => {
    const resultMap = {
      Success: "success",
      Warning: "warning",
      Failed: "danger",
    };

    return <Tag value={rowData.result} severity={resultMap[rowData.result] || null} />;
  };

  const dateBodyTemplate = (rowData, field) => formatDateWithoutTimezone((rowData[field]));

  const durationBodyTemplate = (rowData) => {
    if (rowData.jobStartTime && rowData.jobEndTime) {
      const duration = new Date(rowData.jobEndTime) - new Date(rowData.jobStartTime);
      const minutes = Math.floor(duration / 60000);
      const seconds = Math.floor((duration % 60000) / 1000);
      return `${minutes} mins ${seconds} secs`;
    }
    return rowData.jobStartTime ? "In Progress" : "ERROR: Data Incomplete";
  };

  const viewButtonTemplate = (rowData) => {
    const isDisabled = !rowData.result || !["Failed", "Warning"].includes(rowData.result);

    return (
      <Button
        icon="pi pi-eye"
        label="View"
        className="p-button-text p-button-sm"
        onClick={() => navigate(`/backup-details/${rowData?.veeamJob?.veeamJobId}`)}
        disabled={isDisabled}
      />
    );
  };

  const onPageChange = (event) => {
    setPagination((prev) => ({
      ...prev,
      page: event.page + 1,
      rows: event.rows,
    }));
  };

  const onSort = (event) => {
    setPagination((prev) => ({
      ...prev,
      sortField: event.sortField,
      sortOrder: event.sortOrder === 1 ? "asc" : "desc",
    }));
  };
  const rightToolbarTemplate = () => {
    return (
      <div className="flex justify-between items-center mb-3">
        <div className="flex gap-2">
          {appliedFilters.jobName && (
            <Chip label={`Job: ${appliedFilters.jobName}`} removable onRemove={() => clearFilter("jobName")} />
          )}
          {appliedFilters.status && (
            <Chip label={`Status: ${appliedFilters.status}`} removable onRemove={() => clearFilter("status")} />
          )}
          {appliedFilters.result && (
            <Chip label={`Result: ${appliedFilters.result}`} removable onRemove={() => clearFilter("result")} />
          )}
          {appliedFilters.jobStartDate && (
            <Chip label={`Start Date: ${formatDateOnly((appliedFilters.jobStartDate))}`} removable onRemove={() => clearFilter("jobStartDate")} />
          )}
          {appliedFilters.jobEndDate && (
            <Chip label={`End Date: ${formatDateOnly((appliedFilters.jobEndDate))}`} removable onRemove={() => clearFilter("jobEndDate")} />
          )}
        </div>
        <div className="flex gap-2">
          {Object.keys(appliedFilters).length > 0 && (
            <Button icon="pi pi-times" onClick={clearAllFilters} className="ml-2 p-button-outlined" />
          )}
          <Button icon="pi pi-filter" onClick={() => setIsFilterVisible(true)} className="p-button-outlined" />
        </div>
      </div>
    );
  };
  return (
    <>
      {loading ? (
        <DataTableSkeleton />
      ) : (
        <div className="backup-sessions">
          <h2>Backup Sessions for {selectedCustomerName}</h2>
          <Toolbar className="p-mb-4" end={rightToolbarTemplate}></Toolbar>
          <FiltersPanel appliedFilters={appliedFilters} visible={isFilterVisible} onHide={() => setIsFilterVisible(false)} onApply={applyFilters} />
          <DataTable
            value={backupSessions}
            lazy
            paginator
            first={(pagination.page - 1) * pagination.rows}
            rows={pagination.rows}
            totalRecords={totalRecords}
            loading={loading}
            onPage={onPageChange}
            onSort={onSort}
            sortField={pagination.sortField}
            sortOrder={pagination.sortOrder === "asc" ? 1 : -1}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} backup failures"
            rowsPerPageOptions={[10, 20, 50]}
          >
            <Column field="veeamJob.name" header="Job Name" sortable />
            <Column field="status" header="Status" body={statusBodyTemplate} sortable />
            <Column field="result" header="Result" body={resultBodyTemplate} sortable />
            <Column field="jobStartTime" header="Start Time" body={(rowData) => dateBodyTemplate(rowData, "jobStartTime")} sortable />
            <Column field="jobEndTime" header="End Time" body={(rowData) => dateBodyTemplate(rowData, "jobEndTime")} sortable />
            <Column header="Duration" filter={false} body={durationBodyTemplate} sortable={false} />
            <Column header="View Result" body={viewButtonTemplate} />
          </DataTable>
        </div>
      )}
    </>
  );
};

CustomerVeeamBackupSessions.propTypes = {
  customerId: PropTypes.string.isRequired,
};

export default CustomerVeeamBackupSessions;
