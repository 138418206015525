import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { useEffect, useState } from "react";

const FiltersPanel = ({ users, visible, onHide, onApply, appliedFilters, partnerAccounts, customerAccounts }) => {
    const [filters, setFilters] = useState({
        firstName: "",
        lastName: "",
        email: "",
        role: null,
        partnerId: null,
        customerId: null,
        createdById: null,
        updatedById: null
    });

    const rolesDropdownValues = [
        { label: 'Customer User', value: 'CUSTOMER_USER' },
        { label: 'Customer Admin', value: 'CUSTOMER_ADMIN' },
        { label: 'MSP Admin', value: 'MSP_ADMIN' },
        { label: 'Super Admin', value: 'SUPER_ADMIN' }
    ];

    useEffect(() => {
        if (visible) {
            setFilters(
                appliedFilters || { firstName: "", lastName: "", email: "", role: null, partnerId: null, customerId: null }
            );
        }
    }, [visible, appliedFilters]);

    const handleApplyFilters = () => {
        onApply(filters);
        onHide();
    };
    const usersOption = users.map((acc) => ({
        label: `${acc.firstName}  ${acc.lastName}`,
        value: acc.id,
    }));
    return (
        <Sidebar visible={visible} onHide={onHide} position="right" style={{ width: "25vw" }}>
            <h3 className="mb-3">Filter Users</h3>
            <div className="p-fluid">
                <div className="mb-3">
                    <label>First Name</label>
                    <InputText
                        placeholder="Enter first name"
                        value={filters.firstName || ""}
                        onChange={(e) => setFilters({ ...filters, firstName: e.target.value })}
                    />
                </div>
                <div className="mb-3">
                    <label>Last Name</label>
                    <InputText
                        placeholder="Enter last name"
                        value={filters.lastName || ""}
                        onChange={(e) => setFilters({ ...filters, lastName: e.target.value })}
                    />
                </div>
                <div className="mb-3">
                    <label>Email</label>
                    <InputText
                        placeholder="Enter email"
                        value={filters.email || ""}
                        onChange={(e) => setFilters({ ...filters, email: e.target.value })}
                    />
                </div>
                <div className="mb-3">
                    <label>Role</label>
                    <Dropdown
                        value={filters.role}
                        options={rolesDropdownValues}
                        onChange={(e) => setFilters({ ...filters, role: e.value })}
                        placeholder="Select Role"
                        showClear
                    />
                </div>
                <div className="mb-3">
                    <label>Associated Partner</label>
                    <Dropdown
                        filter
                        filterInputAutoFocus
                        filterPlaceholder="Enter partner name"
                        value={filters.partnerId}
                        options={partnerAccounts}
                        onChange={(e) => setFilters({ ...filters, partnerId: e.value })}
                        placeholder="Select Partner"
                        showClear
                    />
                </div>
                <div className="mb-3">
                    <label>Associated Customer</label>
                    <Dropdown
                        filter
                        filterInputAutoFocus
                        filterPlaceholder="Enter customer name"
                        value={filters.customerId}
                        options={customerAccounts}
                        onChange={(e) => setFilters({ ...filters, customerId: e.value })}
                        placeholder="Select Customer"
                        showClear
                    />
                </div>
                <div className="mb-3">
                    <label>Created By</label>
                    <Dropdown
                        filter
                        filterInputAutoFocus
                        filterPlaceholder="Enter created by name"
                        value={filters.createdById}
                        options={usersOption}
                        onChange={(e) => setFilters({ ...filters, createdById: e.value })}
                        placeholder="Select User"
                        showClear
                    />
                </div>
                <div className="mb-3">
                    <label>Updated By</label>
                    <Dropdown
                        filter
                        filterInputAutoFocus
                        filterPlaceholder="Enter updated by name"
                        value={filters.updatedById}
                        options={usersOption}
                        onChange={(e) => setFilters({ ...filters, updatedById: e.value })}
                        placeholder="Select User"
                        showClear
                    />
                </div>
                <Button
                    label="Apply Filters"
                    icon="pi pi-check"
                    onClick={handleApplyFilters}
                    className="mt-3"
                />
            </div>
        </Sidebar>
    );
};

export default FiltersPanel;
