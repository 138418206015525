import { jwtDecode } from "jwt-decode";
import { getToken } from "../utils/helpers";

export const UserRole = () => {
    const jwt = getToken();
    switch(jwt) {
        case null:
            return "TokenInvalidError";
        default:
            const decoded = jwtDecode(jwt);
            const userRole = (decoded.role);
            return userRole;
    }
  
};

export const UserDept = () => {
    const jwt = getToken();
    switch(jwt) {
        case null:
            return "TokenInvalidError";
        default:
            const decoded = jwtDecode(jwt);
            const userDept = (decoded.department);
            return userDept;
    }
  
};

export default UserRole;