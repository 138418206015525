import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { useState, useEffect } from "react";

const FiltersPanel = ({ visible, onHide, onApply, appliedFilters }) => {
    const [filters, setFilters] = useState({
        jobName: "",
        status: null,
        result: null,
        jobStartDate: null,
        jobEndDate: null,
    });

    const statusOptions = ["Stopped", "Running", "Postprocessing", "Failed", "Working"];
    const resultOptions = ["Success", "Warning", "Failed"];

    useEffect(() => {
        if (visible) {
            setFilters(
                appliedFilters || { jobName: "", status: null, result: null, jobStartDate: null, jobEndDate: null }
            );
        }
    }, [visible, appliedFilters]);
    const handleApplyFilters = () => {
        onApply(filters);
        onHide();
    };
    const handleDateChange = (key, date) => {
        if (date) {
            const localDate = new Date(date);
            localDate.setHours(12, 0, 0, 0);
            setFilters({ ...filters, [key]: localDate });
        } else {
            setFilters({ ...filters, [key]: null });
        }
    };

    return (
        <Sidebar visible={visible} onHide={onHide} position="right" style={{ width: "20vw" }}>
            <h3 className="mb-4">Filter Backup Sessions</h3>
            <div className="p-fluid">
                <div className="mb-4">
                    <label>Job Name</label>
                    <InputText
                        placeholder="Please enter job name"
                        value={filters.jobName || ""}
                        onChange={(e) => setFilters({ ...filters, jobName: e.target.value })}
                    />
                </div>
                <div className="mb-4">
                    <label>Status</label>
                    <Dropdown
                        value={filters.status}
                        options={statusOptions}
                        onChange={(e) => setFilters({ ...filters, status: e.value })}
                        placeholder="Select Status"
                    />
                </div>
                <div className="mb-4">
                    <label>Result</label>
                    <Dropdown
                        value={filters.result}
                        options={resultOptions}
                        onChange={(e) => setFilters({ ...filters, result: e.value })}
                        placeholder="Select Result"
                    />
                </div>
                <div className="mb-4">
                    <label>Start Date</label>
                    <Calendar
                        value={filters.jobStartDate}
                        onChange={(e) => handleDateChange("jobStartDate", e.value)}
                        placeholder="Select start date"
                        dateFormat="yy-mm-dd"
                        showIcon
                    />
                </div>
                <div className="mb-4">
                    <label>End Date</label>
                    <Calendar
                        value={filters.jobEndDate}
                        onChange={(e) => handleDateChange("jobEndDate", e.value)}
                        placeholder="Select end date"
                        dateFormat="yy-mm-dd"
                        showIcon
                    />
                </div>

                <Button
                    label="Apply Filters"
                    icon="pi pi-check"
                    onClick={handleApplyFilters}
                    className="mt-4"
                />
            </div>
        </Sidebar>
    );
};

export default FiltersPanel;
