import React from "react";
import { Outlet } from "react-router-dom";
import SidebarMenu from "./Components/LayoutWrapper/Sidebar";
import TopNavbar from "./Components/LayoutWrapper/TopNavbar";

const Layout = () => {
    return (
        <div className="app-container" style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
            <TopNavbar />
            <div className="main-layout" style={{ display: "flex", flex: 1, overflow: "hidden" }}>
                <SidebarMenu style={{ width: "250px", flexShrink: 0 }} />
                <div className="content" style={{ flex: 1, padding: "20px", overflowX: "auto", minWidth: 0 }}>
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default Layout;
