import React from 'react';
import PartnerAccountAdministration from "../../../Components/AdminManagement/PartnerDashboard/PartnerAccountAdministration";

const PartnerManagement = () => {
    return (
        <div className="p-grid p-nogutter user-admin-panel">
            <div className="p-col content-column p-p-3">
                <div className="content-area">
                    <PartnerAccountAdministration />
                </div>
            </div>
        </div>
    );
}

export default PartnerManagement