import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/logo180.png";
import config from "../../../config";
import { getUserRole, setToken } from "../../../utils/helpers";
import styles from "./Register.module.css";

const Register = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  // For password confirmation
  const watchPassword = watch("password", "");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (message?.type === "success") {
      const timer = setTimeout(() => setMessage(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const onSubmit = async (data, e) => {
    try {
      setMessage({ data: "Registration is in progress...", type: "warning" });
      const response = await fetch(`${config.baseUrl}/auth/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      const resData = await response.json();
      if (!response.ok) {
        setMessage({ data: resData.error || "Registration failed", type: "danger" });
        return;
      }

      setMessage({ data: "Registered successfully.", type: "success" });
      if (resData.token) {
        setToken(resData.token, null);
        const userRole = resData.role || getUserRole();
        if (userRole === "SUPER_ADMIN") {
          navigate("/dashboard");
        } else if (userRole === "CUSTOMER_USER") {
          navigate("/customerlandingpage");
        } else if (userRole === "CUSTOMER_ADMIN") {
          navigate("/customerAdmin/dashboard");
        } else {
          navigate("/login"); // Fallback for unknown roles
        }
      }

      e.target.reset();
    } catch (error) {
      setMessage({ data: error.message || "An unexpected error occurred", type: "danger" });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.registrationFormContainer}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Logo" className={styles.logo} />
        </div>
        {message && (
          <div className={`${styles.alert} ${styles[message.type]}`}>
            {message.data}
            <button className={styles.closeButton} onClick={() => setMessage(null)}>&times;</button>
          </div>
        )}
        <fieldset className={styles.fieldset}>
          <legend className={styles.legend}>SDV Portal Registration</legend>
          <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <div className={styles.nameFields}>
              <div className={styles.formGroup}>
                <label>First Name *</label>
                <input
                  {...register("firstName", {
                    required: "Required", minLength: {
                      value: 2,
                      message: "Minimum 2 characters are allowed",
                    },
                    maxLength: {
                      value: 50,
                      message: "Maximum 50 characters are allowed",
                    },
                  })}
                  placeholder="First Name"
                  className={`${styles.input} ${errors.firstName ? styles.inputError : ""}`}
                />
                {errors.firstName && <span className={styles.errorMessage}>{errors.firstName.message}</span>}
              </div>
              <div className={styles.formGroup}>
                <label>Last Name *</label>
                <input
                  {...register("lastName", {
                    required: "Required", minLength: {
                      value: 2,
                      message: "Minimum 2 characters are allowed",
                    },
                    maxLength: {
                      value: 50,
                      message: "Maximum 50 characters are allowed",
                    },
                  })}
                  placeholder="Last Name"
                  className={`${styles.input} ${errors.lastName ? styles.inputError : ""}`}
                />
                {errors.lastName && <span className={styles.errorMessage}>{errors.lastName.message}</span>}
              </div>
            </div>
            <div className={styles.formGroup}>
              <label>Email Address *</label>
              <input
                {...register("email", { required: "Required", pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: "Enter a valid email address", } })}
                placeholder="Enter email address"
                className={`${styles.input} ${errors.email ? styles.inputError : ""}`}
              />
              {errors.email && <span className={styles.errorMessage}>{errors.email.message}</span>}
            </div>
            <div className={styles.formGroup}>
              <label>Password *</label>
              <div className={styles.passwordWrapper}>
                <input
                  type={showPassword ? "text" : "password"}
                  {...register("password", {
                    required: "Required", minLength: {
                      value: 6,
                      message: "Minimum 6 characters are allowed",
                    },
                    maxLength: {
                      value: 255,
                      message: "Maximum 255 characters are allowed",
                    }
                  })}
                  placeholder="Enter password"
                  className={`${styles.input} ${errors.password ? styles.inputError : ""}`}
                />
                <span className={styles.togglePassword} onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? "Hide" : "Show"}
                </span>
              </div>
              {errors.password && <span className={styles.errorMessage}>{errors.password.message}</span>}
            </div>
            <div className={styles.formGroup}>
              <label>Confirm Password *</label>
              <div className={styles.passwordWrapper}>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  {...register("confirmPassword", { required: "Required", validate: value => value === watchPassword || "Passwords do not match" })}
                  placeholder="Confirm password"
                  className={`${styles.input} ${errors.confirmPassword ? styles.inputError : ""}`}
                />
                <span className={styles.togglePassword} onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                  {showConfirmPassword ? "Hide" : "Show"}
                </span>
              </div>
              {errors.confirmPassword && <span className={styles.errorMessage}>{errors.confirmPassword.message}</span>}
            </div>
            <button type="submit" className={styles.submitButton}>Register</button>
          </form>
        </fieldset>
        <p className={styles.loginRedirect}>Already have an account? <Link to="/login">Login</Link></p>
      </div>
    </div>
  );
};

export default Register;
