import { useEffect } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation
} from "react-router-dom";
import Layout from "./Layout.js";
import CustomerBackUpDashboard from "./Pages/Admin/CustomerBackupsDashboard/CustomerBackUpDashboard.js";
import CustomerManagement from "./Pages/Admin/CustomersManagement/CustomerManagement.js";
import AdminDashboard from "./Pages/Admin/Dashboard/Dashboard.js";
import DBViewer from "./Pages/Admin/DBViewer/DBViewer.js";
import PartnerManagement from './Pages/Admin/PartnersManagement/PartnerManagement.js';
import UserManagement from "./Pages/Admin/UserAdministration/UserManagement.js";
import Login from "./Pages/Auth/Login/Login.js";
import Register from "./Pages/Auth/Register/Register.js";
import ResourceUsageDashboard from './Pages/BackupResourceUsageDashboard/ResourceUsage.js';
import BackUpServerLogDetails from "./Pages/BackUpServerLogDetails/BackUpServerLogDetails.js";
import CustomerAdminDashboard from "./Pages/CustomerAdmin/CustomerAdminWrapper.js";
import CustomerLandingPage from "./Pages/CustomerLandingPage/CustomerLandingPage.js";
import NoAccess from './Pages/NoAccess.js';
import NotFound from "./Pages/NotFound/NotFound.js";
import { getUserRole, isAuthenticated } from "./utils/helpers.js";
import VeeamCompanies from "./Pages/Admin/VeeamCompaniesManagement/CompaniesManagement.js";

const LocalStorageCleanupWrapper = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const allowedRoutes = ["/customerdashboard", "/backup-details"];
    const isAllowed = allowedRoutes.some(route =>
      location.pathname.startsWith(route)
    );

    if (!isAllowed) {
      localStorage.removeItem("selectedNodeKey");
    }
  }, [location.pathname]);

  return <>{children}</>;
};

// Protect routes from unauthorized access
const ProtectedRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/login" />;
};

// Role-based route protection
const getRedirectPath = (role) => {
  switch (role) {
    case "SUPER_ADMIN":
      return "/dashboard";
    case "CUSTOMER_ADMIN":
      return "/customerAdmin/dashboard";
    case "CUSTOMER_USER":
      return "/customerlandingpage";
    default:
      return "/login";
  }
};

const RoleProtectedRoute = ({ allowedRoles, children }) => {
  const role = getUserRole();

  if (!role) {
    return <Navigate to="/login" />;
  }

  if (!allowedRoles.includes(role)) {
    return <Navigate to={getRedirectPath(role)} replace />;
  }

  return children;
};


const AppRoutes = () => (
  <Router>
    <LocalStorageCleanupWrapper>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />

        {/* Protected Routes Wrapped with Layout */}
        <Route element={<ProtectedRoute><Layout /></ProtectedRoute>}>
          {/*  unprotected Routes */}
          <Route path="/backup-details/:id" element={<BackUpServerLogDetails />} />

          {/* Customer Admin Routes - Wrapped Individually */}
          <Route path="/customerlandingpage"
            element={<RoleProtectedRoute allowedRoles={['CUSTOMER_USER']}> <CustomerLandingPage /> </RoleProtectedRoute>} />
          <Route path="/customerAdmin/dashboard" element={<RoleProtectedRoute allowedRoles={['CUSTOMER_ADMIN']}><CustomerAdminDashboard /></RoleProtectedRoute>} />

          {/* Super Admin Routes - Wrapped Individually */}
          <Route path="/dashboard" element={<RoleProtectedRoute allowedRoles={['SUPER_ADMIN']}><AdminDashboard /></RoleProtectedRoute>} />
          <Route path="/customerdashboard" element={<RoleProtectedRoute allowedRoles={['SUPER_ADMIN']}><CustomerBackUpDashboard /></RoleProtectedRoute>} />
          <Route path="/useradminpanel" element={<RoleProtectedRoute allowedRoles={['SUPER_ADMIN']}><UserManagement /></RoleProtectedRoute>} />
          <Route path="/dbedit" element={<RoleProtectedRoute allowedRoles={['SUPER_ADMIN']}><DBViewer /></RoleProtectedRoute>} />
          <Route path="/resourceUsage" element={<RoleProtectedRoute allowedRoles={['SUPER_ADMIN']}><ResourceUsageDashboard /></RoleProtectedRoute>} />
          <Route path="/partnersAccount" element={<RoleProtectedRoute allowedRoles={['SUPER_ADMIN']}><PartnerManagement /></RoleProtectedRoute>} />
          <Route path="/customersAccount" element={<RoleProtectedRoute allowedRoles={['SUPER_ADMIN']}><CustomerManagement /></RoleProtectedRoute>} />
          <Route path="/companies" element={<RoleProtectedRoute allowedRoles={['SUPER_ADMIN']}><VeeamCompanies /></RoleProtectedRoute>} />
        </Route>

        {/* Redirect root to login if not authenticated, otherwise dashboard */}
        <Route
          path="/"
          element={
            isAuthenticated() ? (
              (() => {
                const role = getUserRole();

                if (role === "SUPER_ADMIN") {
                  return <Navigate to="/dashboard" />;
                } else if (role === "CUSTOMER_USER") {
                  return <Navigate to="/customerlandingpage" />;
                } else if (role === "CUSTOMER_ADMIN") {
                  return <Navigate to="/customerAdmin/dashboard" />;
                } else {
                  return <Navigate to="/no-access" />; // Redirect unauthorized users
                }
              })()
            ) : (
              <Navigate to="/login" />
            )
          }
        />


        {/* Catch-all for unknown routes */}
        <Route path="/no-access" element={<NoAccess />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </LocalStorageCleanupWrapper>
  </Router>
);

export default AppRoutes;
