import { Badge } from "primereact/badge";
import { Card } from "primereact/card";
import { Chart } from "primereact/chart";
import React, { useEffect, useState, useTransition } from "react";
import api from "../../services/api";
import ResourceUsageSkeleton from "../../Skeletons/ResourceUsage";
import { isSessionExpiredError } from "../../utils/Common";
import { showToast } from "../../utils/toastService";

const BackupResourceUsage = ({ selectedUser }) => {
    const [backupResourceUsage, setBackupResourceUsage] = useState(null);
    const [isPending, startTransition] = useTransition();
    const [usageHistory, setUsageHistory] = useState([]);

    useEffect(() => {
        if (!selectedUser?.id) return;

        const fetchCustomerStatus = async () => {
            startTransition(async () => {
                try {
                    const response = await api.get(`/customers/veeam-companies/${selectedUser.id}`);
                    const aggregatedData = aggregateUsageData(response.data?.veeamCompanies);
                    setBackupResourceUsage(aggregatedData || null);
                    const usageHistoricalData = formatUsageHistory(response.data?.veeamCompanies)
                    setUsageHistory(usageHistoricalData || [])
                } catch (error) {
                    console.error("Error fetching backup resource usage:", error);
                    if (isSessionExpiredError(error)) {
                        showToast("warn", "Session Expired", "Your session has expired. Please log in again.");
                    } else {
                        setBackupResourceUsage(null);
                        showToast("error", "Error", "No backup resource usage found for the customer.");
                    }
                }
            });
        };

        fetchCustomerStatus();
    }, [selectedUser]);

    const aggregateUsageData = (associatedVeeamCompanies) => {
        return associatedVeeamCompanies.reduce(
            (acc, company) => {
                company.company.backupResourceUsages.forEach((usage) => {
                    acc.storageQuota += Number(usage.storageQuota || 0);
                    acc.usedStorageQuota += Number(usage.usedStorageQuota || 0);
                    acc.performanceTierUsage += Number(usage.performanceTierUsage || 0);
                    acc.capacityTierUsage += Number(usage.capacityTierUsage || 0);
                    acc.archiveTierUsage += Number(usage.archiveTierUsage || 0);
                    acc.serverBackups += usage.serverBackups || 0;
                    acc.workstationBackups += usage.workstationBackups || 0;
                    acc.vmBackups += usage.vmBackups || 0;
                });
                return acc;
            },
            {
                storageQuota: 0,
                usedStorageQuota: 0,
                performanceTierUsage: 0,
                capacityTierUsage: 0,
                archiveTierUsage: 0,
                serverBackups: 0,
                workstationBackups: 0,
                vmBackups: 0
            }
        );
    };

    const formatUsageHistory = (historyData) => {
        if (!historyData || historyData.length === 0) {
            return [];
        }

        return historyData.flatMap((vc) =>
            vc.company?.backupResourceUsageHistory?.map((entry) => ({
                date: new Date(entry.createdAt).toLocaleDateString(),
                usage: Number(entry.usedStorageQuota) || 0, // Ensure it's a number
                companyName: vc.company?.name || "Unknown", // Optional: Include company name
            })) ?? [] // Use `?? []` instead of `|| []` to avoid null propagation issues
        );
    };



    const bytesToTB = (bytes) => (bytes ? (bytes / 1024 ** 4).toFixed(2) : "0");
    // const bytesToTB = (bytes) => (bytes / 1e12).toFixed(2);

    if (isPending || !backupResourceUsage) return <ResourceUsageSkeleton />;

    return (
        <div className="card flex flex-col gap-6 p-6">
            <div className="flex flex-col md:flex-row gap-6">
                <div className="flex-1">
                    <h3>Historical Usage</h3>
                    <Card>
                        <Chart
                            type="line"
                            width="40rem"
                            height="40vh"
                            data={{
                                labels: usageHistory.map(entry => entry.date),
                                datasets: [{
                                    label: "Used Storage (TB)",
                                    data: usageHistory.map(entry => parseFloat(bytesToTB(entry.usage))),
                                    borderColor: "#42A5F5",
                                    backgroundColor: "rgba(66, 165, 245, 0.2)",
                                    fill: true,
                                    tension: 0.4,
                                    pointRadius: 5,
                                    pointBackgroundColor: "#42A5F5",
                                    hoverRadius: 7
                                }]
                            }}
                            options={{
                                maintainAspectRatio: false,
                                responsive: true,
                                scales: { x: { title: { display: true, text: "Date" } }, y: { beginAtZero: false } },
                                plugins: {
                                    legend: { display: true },
                                    tooltip: { callbacks: { label: tooltipItem => `${tooltipItem.raw.toFixed(2)} TB` } }
                                },
                                animation: { duration: 1000, easing: "easeOutCubic" }
                            }}
                        />
                    </Card>

                </div>
                <div className="flex-1">
                    <h3>Storage Usage</h3>
                    <p>
                        <strong>Used Storage:</strong> {bytesToTB(backupResourceUsage.usedStorageQuota)} TB / {bytesToTB(backupResourceUsage.storageQuota)} TB
                    </p>
                    <Card>
                        <Chart
                            type="bar"
                            width="40rem"
                            height="40vh"
                            data={{
                                labels: ["Used Storage", "Total Storage"],
                                datasets: [{
                                    label: "Storage (TB)",
                                    backgroundColor: ["rgba(255, 77, 77, 0.8)", "rgba(77, 121, 255, 0.8)"],
                                    borderColor: ["#ff4d4d", "#4d79ff"],
                                    borderWidth: 2,
                                    borderRadius: 8,
                                    hoverBackgroundColor: ["#ff1a1a", "#1a53ff"],
                                    data: [
                                        parseFloat(bytesToTB(backupResourceUsage.usedStorageQuota)),
                                        parseFloat(bytesToTB(backupResourceUsage.storageQuota))
                                    ],
                                }],
                            }}
                            options={{
                                maintainAspectRatio: false,
                                responsive: true,
                                scales: { x: { grid: { display: false } }, y: { beginAtZero: true } },
                                plugins: {
                                    legend: { display: false },
                                    tooltip: { callbacks: { label: tooltipItem => `${tooltipItem.raw.toFixed(2)} TB` } }
                                },
                                animation: { duration: 1200, easing: "easeOutElastic" }
                            }}
                        />
                    </Card>

                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-6">
                <Card>
                    <h3>Storage Tier Breakdown</h3>
                    <Chart
                        type="pie"
                        data={{
                            labels: ["Performance Tier", "Capacity Tier", "Archive Tier"],
                            datasets: [{
                                data: [
                                    parseFloat(bytesToTB(backupResourceUsage.performanceTierUsage)),
                                    parseFloat(bytesToTB(backupResourceUsage.capacityTierUsage)),
                                    parseFloat(bytesToTB(backupResourceUsage.archiveTierUsage))
                                ],
                                backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                            }],
                        }}
                    />
                </Card>

                <div className="flex">
                    <Card className="p-6">
                        <h3 className="text-2xl font-bold mb-4">Backup Summary</h3>
                        <div className="flex flex-col gap-3 text-lg">
                            <div className="flex items-center gap-3">
                                <i className="pi pi-server text-blue-500 text-2xl"></i>
                                <span className="text-gray-700 font-medium">Server Backups:</span>
                                <Badge value={backupResourceUsage.serverBackups} severity="info" />
                            </div>
                            <div className="flex items-center gap-3">
                                <i className="pi pi-desktop text-green-500 text-2xl"></i>
                                <span className="text-gray-700 font-medium">Workstation Backups:</span>
                                <Badge value={backupResourceUsage.workstationBackups} severity="success" />
                            </div>
                            <div className="flex items-center gap-3">
                                <i className="pi pi-database text-yellow-500 text-2xl"></i>
                                <span className="text-gray-700 font-medium">VM Backups:</span>
                                <Badge value={backupResourceUsage.vmBackups} severity="warning" />
                            </div>
                        </div>

                        <div className="mt-6" style={{ height: "300px" }}>
                            <Chart type="bar" data={{
                                labels: ["Server", "Workstation", "VM"],
                                datasets: [{
                                    label: "Backups",
                                    backgroundColor: ["#42A5F5", "#66BB6A", "#FFA726"],
                                    borderWidth: 2,
                                    borderRadius: 8,
                                    data: [
                                        backupResourceUsage.serverBackups,
                                        backupResourceUsage.workstationBackups,
                                        backupResourceUsage.vmBackups
                                    ]
                                }],
                            }} options={{
                                responsive: true,
                                scales: { x: { grid: { display: false } }, y: { beginAtZero: true } },
                                animation: { duration: 1200, easing: "easeOutElastic" }
                            }} />
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    )
};

export default BackupResourceUsage;
