import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/lara-light-cyan/theme.css"; // ✅ Theme import
import "primereact/resources/primereact.min.css"; // PrimeReact components CSS
import "primeicons/primeicons.css"; // PrimeIcons for icons
import "primeflex/primeflex.css"; // PrimeFlex for utility classes
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import "./index.css";
import Routes from "./Routes";
import { PrimeReactProvider } from "primereact/api";
import { ToastWrapper } from "./utils/toastService"; 

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    <PrimeReactProvider>
      <ToastWrapper />
    <Routes />
    </PrimeReactProvider>
  </StrictMode>
);

