// import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { Tree } from 'primereact/tree';
import React, { useCallback, useEffect, useState, useTransition } from 'react';
import RecordForm from '../../../Components/Dbviewerforms/RecordForm';
import api from '../../../services/api';
import DataTableSkeleton from "../../../Skeletons/DataTable";
import { isSessionExpiredError } from '../../../utils/Common';
import { showToast } from '../../../utils/toastService';
import './DBViewer.css';

export default function DBViewer() {
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [isLoading, setIsloading] = useTransition();
  const [formVisible, setFormVisible] = useState(false);
  const [formData, setFormData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    rows: 10,
    sortField: "",
    sortOrder: "desc",
  });

  // Fetch table names
  useEffect(() => {
    const fetchTables = async () => {
      try {
        const res = await api.get('/db/tables');
        setTables(res.data || []);
      } catch (error) {
        if (isSessionExpiredError(error)) {
          showToast('warn', 'Session Expired', 'Your session has expired. Please log in again.');
        } else {
          showToast('error', 'Error', 'Failed to load tables.');
        }
        console.log('Error fetching tables:', error);
      }
    };
    fetchTables();
  }, []);

  // Fetch table data
  const fetchTableData = useCallback(async () => {
    if (!selectedTable) return;
    setIsloading(async () => {
      try {
        const params = {
          page: pagination.page,
          limit: pagination.rows,
          sortField: pagination.sortField,
          sortOrder: pagination.sortOrder,
        };
        const res = await api.get(`/db/tables/${selectedTable}`, { params });
        setColumns(res.data.columns || []);
        setRows(res.data.rows || []);
        setTotalRecords(res.data?.pagination?.totalRecords);
      } catch (error) {
        if (isSessionExpiredError(error)) {
          showToast('warn', 'Session Expired', 'Your session has expired. Please log in again.');
        } else {
          showToast('error', 'Error', 'Failed to load table data.');

        }
        console.error('Error fetching table data:', error);
      }
    });
  }, [selectedTable, pagination]);

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  const handleTableSelect = (event) => {
    setPagination({
      page: 1,
      rows: 10,
      sortField: "",
      sortOrder: "desc",
    });
    setSelectedTable(event.node.key);
  };

  const onRowSelect = (e) => {
    setIsEdit(true);
    setFormData({ ...e.data });
    setFormVisible(true);
  };

  // const handleAddNew = () => {
  //   if (!columns.length) return;
  //   const initialFormData = columns.reduce((acc, col) => {
  //     acc[col.column_name] = ''; // Default empty values for new record
  //     return acc;
  //   }, {});
  //   setIsEdit(false);
  //   setFormData(initialFormData);
  //   setFormVisible(true);
  // };

  const onPageChange = (event) => {
    setPagination((prev) => ({
      ...prev,
      page: event.page + 1,
      rows: event.rows,
    }));
  };

  const onSort = (event) => {
    setPagination((prev) => ({
      ...prev,
      sortField: event.sortField,
      sortOrder: event.sortOrder === 1 ? "asc" : "desc",
    }));
  };
  const handleSuccess = () => {
    setIsEdit(false);
    setFormVisible(false);
    fetchTableData(); // Refresh data after add/update
  };

  const DataTableHeader = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Table: {selectedTable}</h4>
      <div className="flex gap-2">
        {/* <Button label="Add New" icon="pi pi-plus" onClick={handleAddNew} /> */}
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            type="search"
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
          />
        </IconField>
      </div>
    </div>
  );

  return (
    <div className="dbviewer-wrapper">
      <div className="dbviewer-tablelist w-auto">
        <h3>Database Tables</h3>
        <Tree
          loading={isLoading}
          value={tables.map((tbl) => ({ key: tbl, label: tbl }))}
          selectionMode="single"
          selectionKeys={selectedTable}
          onSelect={handleTableSelect}
          style={{ border: 'none' }}
        />
      </div>

      <div className="dbviewer-main">
        {!selectedTable ? (
          <h4>Please select a table on the left</h4>
        ) : isLoading ? (
          <DataTableSkeleton />
        ) : (
          <DataTable
            header={DataTableHeader}
                value={rows}
                lazy
                paginator
                first={(pagination.page - 1) * pagination.rows}
                rows={pagination.rows}
                totalRecords={totalRecords}
                loading={isLoading}
                onPage={onPageChange}
                onSort={onSort}
                sortField={pagination.sortField}
                sortOrder={pagination.sortOrder === "asc" ? 1 : -1}
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                rowsPerPageOptions={[10, 20, 50]}
            scrollable
            selectionMode="single"
            onRowSelect={onRowSelect}
            globalFilter={globalFilter}
            emptyMessage="No records found"
          >
            {columns.map((col) => (
              <Column
                key={col.column_name}
                field={col.column_name}
                header={col.column_name}
                sortable
                filter
                filterPlaceholder={`Search ${col.column_name}`}
                body={(rowData) => {
                  const value = rowData[col.column_name];
                  return typeof value === "object" ? JSON.stringify(value) : value;
                }}
              />
            ))}
          </DataTable>
        )}
      </div>
      <RecordForm
        isEdit={isEdit}
        visible={formVisible}
        setVisible={setFormVisible}
        tableName={selectedTable}
        initialData={formData}
        onSuccess={handleSuccess}
      />
    </div>
  );
}
