import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import { Card } from 'primereact/card';
import 'primereact/resources/primereact.min.css';
import React from 'react';
import { Col } from "react-bootstrap";
import CloudConnectTenants from '../../../Components/CloudConnectTenants';
import "../../../assets/styles/Dashboard.css";

import FailedVeeamBackupSessions from '../../../Components/BackupSessions_Last48Hours';
import {
  kpiData,
} from "./DashboardData";

const Dashboard = () => {

  return (
    <div className='main-dashbaord'>
      <div className='d-flex'>
        {kpiData.map((kpi, index) => (
          <Col key={index} xs={3} sm={6} md={3} className="mb-3">
            <Card className="kpi-card shadow-sm p-3">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h3 className="mb-1">{kpi.value}</h3>
                  <p className="mb-0">{kpi.label}</p>
                </div>
                <i className={`${kpi.icon} pi-2x`} style={{ color: kpi.color }}></i>
              </div>
            </Card>
          </Col>
        ))}
      </div>
      <div className="d-flex p-grid p-mb-4">
        <div className="p-col">
          <Card className='p-shadow-3'>
            <CloudConnectTenants />
          </Card>
        </div>
        <div className="p-col">
          <Card className='p-shadow-3'>
            <FailedVeeamBackupSessions days={2} />
          </Card>
        </div>
      </div>
      <div className="p-col">
        <Card className='p-shadow-3'>
          <FailedVeeamBackupSessions days={7} />
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;
