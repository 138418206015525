
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tag } from 'primereact/tag';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import React, { useCallback, useEffect, useState, useTransition } from 'react';
import api from '../../../services/api';
import DataTableSkeleton from '../../../Skeletons/DataTable';
import { formatDateOnly, formatDateWithoutTimezone, generateAccountIdentifier, isSessionExpiredError } from '../../../utils/Common';
import { showToast } from '../../../utils/toastService';
import FiltersPanel from '../../Filters/CustomerAdministration/filterPanel';
import CustomerForm from './CustomerAdministrationForm';

const CustomerAdministration = () => {
    const [isLoading, setIsloading] = useTransition();
    const [companies, setCompanies] = useState([]);
    const [customerDialog, setCustomerDialog] = useState(false);
    const [deleteCustomerDailog, setDeleteCustomerDialog] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [customerAccounts, setCustomerAccounts] = useState([]);
    const [partnerAccounts, setPartnerAccounts] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState({});
    const [pagination, setPagination] = useState({
        page: 1,
        rows: 10,
        sortField: "",
        sortOrder: "desc",
    });

    const fetchCustomerAccounts = useCallback(async () => {
        setIsloading(async () => {
            try {
                const params = {
                    page: pagination.page,
                    limit: pagination.rows,
                    sortField: pagination.sortField,
                    sortOrder: pagination.sortOrder,
                    ...appliedFilters,
                };
                const response = await api.get('/customers', { params });
                setCustomerAccounts(response.data.data || []);
                setTotalRecords(response.data?.pagination.totalRecords);
            } catch (error) {
                if (isSessionExpiredError(error)) {
                    showToast('warn', 'Session Expired', 'Your session has expired. Please log in again.');
                } else {
                    showToast("error", "Error", "Failed to fetch customer accounts.");
                }
            }
        });
    }, [pagination, appliedFilters]);

    const fetchPartnerAccounts = useCallback(async () => {
        setIsloading(async () => {
            try {
                const response = await api.get('/partneraccounts?dropdown=true');
                const data = response.data?.map((acc) => ({
                    label: acc.name,
                    value: acc.id,
                }));
                setPartnerAccounts(data);
            } catch (error) {
                if (isSessionExpiredError(error)) {
                    showToast('warn', 'Session Expired', 'Your session has expired. Please log in again.');
                } else {
                    showToast("error", "Error", "Failed to fetch partner accounts.");
                }
                console.log('Failed to fetch partner accounts', error);
            }
        });
    }, []);

    const fetchCompanies = useCallback(async () => {
        setIsloading(async () => {
            try {
                const response = await api.get("/veeam-companies?dropdown=true");
                setCompanies(response.data || []);
            } catch (error) {
                if (isSessionExpiredError(error)) {
                    showToast('warn', 'Session Expired', 'Your session has expired. Please log in again.');
                } else {
                    showToast("error", "Error", "Failed to fetch companies.");
                }
                console.error("Error fetching companies:", error);
            }
        });
    }, []);

    const handleToast = (severityType, summaryType, message) => {
        showToast(severityType, summaryType, message);
    }
    const hideDialog = () => {
        setCustomerDialog(false);
    };

    const hideDeleteUserDialog = () => {
        setDeleteCustomerDialog(false);
    };
    const deleteUser = async () => {
        if (!selectedCustomer?.id) {
            handleToast('error', 'Error', "No customer selected for deletion");
            return;
        }
        setIsloading(async () => {
            try {
                await api.delete(`/customers/${selectedCustomer.id}`);
                handleToast('success', 'Success', "User Deleted");
                await fetchCustomerAccounts();
            } catch (error) {
                console.error('Error deleting customer:', error);
                handleToast('error', 'Error', error.response?.data?.message || "Failed to delete customer");
            } finally {
                setDeleteCustomerDialog(false);
                setSelectedCustomer(null);
            }
        });
    };
    useEffect(() => {
        fetchPartnerAccounts();
        fetchCompanies();
    }, [fetchPartnerAccounts, fetchCompanies]);

    useEffect(() => {
        fetchCustomerAccounts();
    }, [fetchCustomerAccounts]);

    const confirmDeleteUser = (customer) => {
        setSelectedCustomer(customer);
        setDeleteCustomerDialog(true);
    };
    const openNew = () => {
        setSelectedCustomer(null);
        setCustomerDialog(true);
    }
    const editExistingUser = (selectedData) => {
        setSelectedCustomer(selectedData);
        setCustomerDialog(true);
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment key={"table-data"}>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-mr-2"
                    onClick={() => { editExistingUser(rowData) }} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-danger"
                    onClick={() => confirmDeleteUser(rowData)} />
            </React.Fragment>
        );
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-button-rounded p-mr-2" onClick={openNew} />
            </React.Fragment>
        );
    };

    const deleteUserDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUserDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteUser} />
        </React.Fragment>
    );
    const handleSubmit = async (formData) => {
        const accountIdentifier = generateAccountIdentifier(formData.name);
        try {
            const customerObj = {
                name: formData.name,
                description: formData.description,
                status: formData.status,
                partnerAccountId: formData.partnerAccount || null,
                accountIdentifier: accountIdentifier,
                veeamCompanyIds: formData.veeamCompanyIds || []
            };

            if (formData && formData.id) {
                await api.put(`/customers/${formData.id}`, customerObj);
                handleToast('success', 'Success', "Customer Updated");
            } else {
                await api.post('/customers', customerObj);
                handleToast('success', 'Success', "Customer Created");
            }

            fetchCustomerAccounts();
            setCustomerDialog(false);
        } catch (error) {
            console.error("Error submitting customer:", error);
            handleToast('error', 'Error', error.response?.data?.error || error.response?.data?.message || "An unexpected error occurred");
        }
    };
    const dateBodyTemplate = (rowData, field) => formatDateWithoutTimezone((rowData[field]));

    const clearFilter = (filterKey) => {
        setAppliedFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters };
            delete updatedFilters[filterKey];
            return updatedFilters;
        });
    };

    const clearAllFilters = () => {
        setPagination({
            page: 1,
            rows: 10,
            sortField: "",
            sortOrder: "desc",
        });
        setAppliedFilters({});
    };

    const applyFilters = (filters) => {
        setAppliedFilters(filters);
    };

    const onPageChange = (event) => {
        setPagination((prev) => ({
            ...prev,
            page: event.page + 1,
            rows: event.rows,
        }));
    };

    const onSort = (event) => {
        setPagination((prev) => ({
            ...prev,
            sortField: event.sortField,
            sortOrder: event.sortOrder === 1 ? "asc" : "desc",
        }));
    };


    const rightToolbarTemplate = () => {
        const filterLabels = {
            name: "Name",
            status: "Status",
            accountIdentifier: "Identifier",
            updatedAt: "Updated At",
            partnerId: "Partner",
        };

        const getPartnerName = (partnerId) => {
            return partnerAccounts.find(partner => partner.value === partnerId)?.label || "Unknown Partner";
        };
        return (
            <div className="flex justify-between items-center mb-3">
                <div className="flex gap-2">
                    {Object.entries(appliedFilters).map(([key, value]) => {
                        if (!value || !filterLabels[key]) return null;
                        let displayValue = value;
                        if (key === "partnerId") displayValue = getPartnerName(value);
                        if (key === "updatedAt") displayValue = formatDateOnly(value);

                        return (
                            <Chip
                                key={key}
                                label={`${filterLabels[key]}: ${displayValue}`}
                                removable
                                onRemove={() => clearFilter(key)}
                            />
                        );
                    })}
                </div>
                <div className="flex gap-2">
                    {Object.keys(appliedFilters).length > 0 && (
                        <Button icon="pi pi-times" onClick={clearAllFilters} className="ml-2 p-button-outlined" />
                    )}
                    <Button icon="pi pi-filter" onClick={() => setIsFilterVisible(true)} className="p-button-outlined" />
                </div>
            </div>
        );
    };

    const PartnerAssociationBodyTemplate = (rowData) => {
        return rowData.PartnerAccount?.name ? rowData.PartnerAccount.name : 'None';
    };
    const CustomerAssociationBodyTemplate = (rowData) => {
        if (!rowData.veeamCompanies || rowData.veeamCompanies.length === 0) {
            return 'None';
        }
        return rowData.veeamCompanies.map(account => account.name).join(', ');
    };
    const accountStatusBodyTemplate = (rowData) => {
        let severity = 'info';
        switch (rowData.status) {
            case 'Active': severity = 'success'; break;
            case 'Disabled': severity = 'danger'; break;
            default: severity = 'info';
        }
        return <Tag value={rowData.status} severity={severity} />;
    };
    return (
        <div className="datatable-crud-demo">
            <div className="card">
                <h4>Customer Administration</h4>
                {isLoading ? (<DataTableSkeleton />) : (
                    <>
                        <Toolbar className="p-mb-4" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>
                        <FiltersPanel partnerAccounts={partnerAccounts} appliedFilters={appliedFilters} visible={isFilterVisible}
                            onHide={() => setIsFilterVisible(false)} onApply={applyFilters} />
                        <DataTable
                            value={customerAccounts}
                            lazy
                            paginator
                            first={(pagination.page - 1) * pagination.rows}
                            rows={pagination.rows}
                            totalRecords={totalRecords}
                            loading={isLoading}
                            onPage={onPageChange}
                            onSort={onSort}
                            sortField={pagination.sortField}
                            sortOrder={pagination.sortOrder === "asc" ? 1 : -1}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} customers"
                            rowsPerPageOptions={[10, 20, 50]}
                            className="p-datatable-gridlines">
                            <Column field="name" header="Name" sortable />
                            <Column field="status" header="Status" body={accountStatusBodyTemplate} sortable />
                            <Column field="accountIdentifier" header="Identifier" sortable />
                            <Column field="updatedAt" header="Updated At"
                                body={(rowData) => dateBodyTemplate(rowData, "updatedAt")}
                                sortable />
                            <Column header="Associated Partner" body={PartnerAssociationBodyTemplate} />
                            <Column field="description" header="Description" />
                            <Column header="Associated Company" body={CustomerAssociationBodyTemplate} />
                            <Column header="Action" body={actionBodyTemplate} />
                        </DataTable>
                        {customerDialog && <CustomerForm companyList={companies} customerDialog={customerDialog} hideDialog={hideDialog} onSubmit={handleSubmit}
                            partnerAccounts={partnerAccounts} defaultValues={selectedCustomer} />}
                        <Dialog visible={deleteCustomerDailog} style={{ width: '450px' }} header="Confirm" modal footer={deleteUserDialogFooter} onHide={hideDeleteUserDialog}>
                            <div className="confirmation-content" style={{ textAlign: 'center' }}>
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', color: 'var(--red-500)' }} />
                                {selectedCustomer && <span>Are you sure you want to delete <b>{selectedCustomer.name} {selectedCustomer.lastName}</b>?</span>}
                            </div>
                        </Dialog>
                    </>
                )}
            </div>
        </div>
    )
}

export default CustomerAdministration;