import { Card } from 'primereact/card';
import React, { useEffect, useState } from 'react';
import BackupResourceUsage from '../../Components/BackupResourceUsage/BackupResourceUsage';
import CustomerBackupStatusMappedCompanyTable from '../../Components/CustomerAdmin/CustomerBackupStatusMappedCompanyTable';
import CustomerVeeamBackupSessions from '../../Components/CustomerVeeamBackupSessions';
import PhysicalServersTree from '../../Components/PhysicalServersTree';
import ReplicaGrid from '../../Components/ReplicaGrid';
import api from '../../services/api';
import { isSessionExpiredError } from '../../utils/Common';
import { getCurrentUser } from '../../utils/helpers';
import { showToast } from '../../utils/toastService';

const CustomerAdminWrapper = () => {
    const [user, setUser] = useState(null);
    const loggedInUser = getCurrentUser();

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await api.get(`/users/${loggedInUser.id}`);
                setUser(response.data || null);
            } catch (error) {
                if (isSessionExpiredError(error)) {
                    showToast('warn', 'Session Expired', 'Your session has expired. Please log in again.');
                } else {
                    showToast("error", "Error", "Failed to fetch customers.");
                }
                console.error("Error fetching customers:", error);
            }
        };
        fetchCustomers();
    }, [loggedInUser.id]);

    return (
        <div className='customer-wrapper'>
            <div className="w-100">
                <div className="card flex justify-content-center">
                    <Card title={`Resource Usage for ${user?.customerAccount?.name}`}>
                        <BackupResourceUsage company={user?.customerId} />
                    </Card>
                </div>
            </div>
            <div className="w-100">
                <div className="card flex justify-content-center">
                    <Card>{<CustomerBackupStatusMappedCompanyTable customerId={user?.customerId} />}</Card>
                </div>
                <div className="card flex justify-content-center">
                    <Card title="VBR Overview">
                        {<PhysicalServersTree customerId={user?.customerId} />}
                    </Card>
                </div>
                <div className="card flex justify-content-center">
                    <Card>{<ReplicaGrid customerId={user?.customerId} />}</Card>
                </div>
                <div className="card flex justify-content-center">
                    <Card>{<CustomerVeeamBackupSessions selectedCustomerName={user?.customerAccount?.name} customerId={user?.customerId} />}</Card>
                </div>
            </div>
        </div>
    )
}

export default CustomerAdminWrapper;