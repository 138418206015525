import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";

const PartnerAdministrationForm = ({ partnerDialog, hideDialog, onSubmit, defaultValues }) => {
    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        defaultValues: {
            id: defaultValues?.id,
            name: defaultValues?.name || "",
            status: defaultValues?.status || "",
            description: defaultValues?.description || "",
        },
    });

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    const handleClose = () => {
        reset();
        hideDialog();
    };

    return (
        <Dialog visible={!!partnerDialog} style={{ width: '450px' }} header="Partner Account Details" modal className="p-fluid"
            footer={
                <div>
                    <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={handleClose} />
                    <Button label="Save" icon="pi pi-check" className="p-button-primary" onClick={handleSubmit(onSubmit)} />
                </div>
            }
            onHide={handleClose}
        >
            <div className="field">
                <label htmlFor="name">Partner Account Name <span style={{ color: "red" }}>*</span></label>
                <Controller
                    name="name"
                    control={control}
                    rules={{
                        required: "Account name is required",
                        pattern: {
                            value: /^[A-Za-z\s]+$/,
                            message: "Only letters and spaces are allowed",
                        },
                    }}
                    render={({ field }) => (
                        <InputText
                            placeholder="Please enter account name"
                            id="name"
                            {...field}
                            className={errors.name ? "p-invalid" : ""}
                        />
                    )}
                />
                {errors.name && <small className="p-error">{errors.name.message}</small>}
            </div>

            <div className="field">
                <label htmlFor="status">Account Status <span style={{ color: "red" }}>*</span></label>
                <Controller
                    name="status"
                    control={control}
                    rules={{
                        required: "Account status is required",
                        pattern: {
                            value: /^[A-Za-z\s]+$/,
                            message: "Only letters and spaces are allowed",
                        },
                    }}
                    render={({ field }) => (
                        <InputText
                            placeholder="Please enter account status"
                            id="status"
                            {...field}
                            className={errors.status ? "p-invalid" : ""}
                        />
                    )}
                />
                {errors.status && <small className="p-error">{errors.status.message}</small>}
            </div>

            <div className="field">
                <label htmlFor="description">Account Description <span style={{ color: "red" }}>*</span></label>
                <Controller
                    name="description"
                    control={control}
                    rules={{
                        required: "Description is required",
                    }}
                    render={({ field }) => (
                        <InputTextarea
                            id="description"
                            placeholder="Please enter account description"
                            {...field}
                            className={errors.description ? "p-invalid" : ""}
                        />
                    )}
                />
                {errors.description && <small className="p-error">{errors.description.message}</small>}
            </div>
        </Dialog>
    );
};

export default PartnerAdministrationForm;
