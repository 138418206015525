import { createRef } from "react";
import { Toast } from "primereact/toast";

export const toastRef = createRef();

export const showToast = (severity, summary, detail) => {
    if (toastRef.current) {
        toastRef.current.show({ severity, summary, detail, life: 3000 });
    }
};

export const ToastWrapper = () => {
    return <Toast ref={toastRef} />;
};
