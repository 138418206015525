// src/utils/helpers.js

import { jwtDecode } from "jwt-decode";

export const getToken = () => localStorage.getItem("access_token");

export const setToken = (accessToken, refreshToken) => {
  localStorage.setItem("access_token", accessToken);
  localStorage.setItem("refresh_token", refreshToken);
};

export const clearAuth = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
};

export const getCurrentUser = () => {
  const jwt = getToken();
  if (!jwt) return null;

  try {
    return jwtDecode(jwt);
  } catch {
    return null;
  }
};

export const isAuthenticated = () => {
  const token = getToken();
  if (!token) return false;

  try {
    const { exp } = JSON.parse(atob(token.split(".")[1]));
    return exp * 1000 > Date.now();
  } catch (error) {
    return false;
  }
};

export const getUserRole = () => {
  const token = getToken();
  if (!token) return null;

  try {
    const decoded = jwtDecode(token);
    return decoded.role || null; // Extract role from token
  } catch (error) {
    return null;
  }
};