import { Navigate } from "react-router-dom";
import { isAuthenticated } from "../utils/helpers";

const NoAccess = () => {
    return isAuthenticated() ? (
        <div>
            <h1>Access Denied</h1>
            <p>You do not have permission to view this page.</p>
        </div>
    ) : (
        <Navigate to="/login" />
    );
};

export default NoAccess;
