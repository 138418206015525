
import React from 'react';
import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function DataTableSkeleton() {
  const items = Array.from({ length: 5 }, (v, i) => i);

  return (
    <div className="card">
      <DataTable value={items} className="p-datatable-striped">
        <Column style={{ width: '25%' }} body={<Skeleton />}></Column>
        <Column style={{ width: '25%' }} body={<Skeleton />}></Column>
        <Column style={{ width: '25%' }} body={<Skeleton />}></Column>
        <Column style={{ width: '25%' }} body={<Skeleton />}></Column>
      </DataTable>
    </div>
  );
}
