// src/Components/AdminDashboard/PartnerAccountTable.jsx

import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import React, { useCallback, useEffect, useState, useTransition } from 'react';
import api from '../../../services/api';
import { formatDateOnly, formatDateWithoutTimezone, isSessionExpiredError } from '../../../utils/Common';
import { showToast } from '../../../utils/toastService';
import FiltersPanel from '../../Filters/PartnerAdministration/filterPanel';
import PartnerForm from './PartnerAdministrationForm';

const PartnerAccountAdministration = () => {
  const [partnerAccounts, setPartnerAccounts] = useState([]);
  const [loading, setLoading] = useTransition();
  const [accountDialog, setAccountDialog] = useState(false);
  const [deleteAccountDialog, setDeleteAccountDialog] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    rows: 10,
    sortField: "",
    sortOrder: "desc",
  });

  const fetchPartnerAccounts = useCallback(async () => {
    setLoading(async () => {
      try {
        const params = {
          page: pagination.page,
          limit: pagination.rows,
          sortField: pagination.sortField,
          sortOrder: pagination.sortOrder,
          ...appliedFilters,
        };
        const response = await api.get('/partneraccounts', { params });
        setPartnerAccounts(response.data.data || []);
        setTotalRecords(response.data?.pagination.totalRecords);
      } catch (error) {
        console.error('Error fetching partners account:', error);
        if (isSessionExpiredError(error)) {
          showToast('warn', 'Session Expired', 'Your session has expired. Please log in again.');
        } else {
          showToast('error', 'Error', 'Failed to fetch partner accounts.');
        }
      }
    });
  }, [pagination, appliedFilters]);

  useEffect(() => {
    fetchPartnerAccounts();
  }, [fetchPartnerAccounts]);

  const openNew = () => {
    setSelectedAccount(null);
    setAccountDialog(true);
  }

  const hideDialog = () => {
    setAccountDialog(false);
  };

  const hideDeleteAccountDialog = () => {
    setDeleteAccountDialog(false);
  };

  const handleSubmit = async (formData) => {
    try {
      const partnerObj = {
        name: formData.name,
        status: formData.status,
        description: formData.description,
      };

      if (formData && formData.id) {
        await api.put(`/partneraccounts/${formData.id}`, partnerObj);
        showToast('success', 'Success', 'Partner Account Updated');
      } else {
        await api.post('/partneraccounts', partnerObj);
        showToast('success', 'Success', 'Partner Account Created');
      }
      fetchPartnerAccounts();
      setAccountDialog(false);
    } catch (error) {
      console.error("Error submitting partner:", error);
      showToast('error', 'Error', error.response?.data?.error || error.response?.data?.message || "An unexpected error occurred");
    }
  };

  const deleteAccount = async () => {
    if (!selectedAccount?.id) {
      showToast('error', 'Error', 'No account selected for deletion');
      return;
    }
    setLoading(async () => {
      try {
        await api.delete(`/partneraccounts/${selectedAccount.id}`);
        showToast('success', 'Success', 'Partner Account Deleted');
        fetchPartnerAccounts();

      } catch (error) {
        console.error('Error deleting partner account:', error);
        showToast('error', 'Error', error.response?.data?.message || "Failed to delete partner account");
      } finally {
        setDeleteAccountDialog(false);
        setSelectedAccount(null);
      }
    });
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button label="New" icon="pi pi-plus" className="p-button-success p-button-rounded p-mr-2" onClick={openNew} />
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    const filterLabels = {
      name: "Name",
      status: "Status",
      updatedAt: "Updated At",
    };

    return (
      <div className="flex justify-between items-center mb-3">
        <div className="flex gap-2">
          {Object.entries(appliedFilters).map(([key, value]) => {
            if (!value || !filterLabels[key]) return null;
            let displayValue = value;
            if (key === "updatedAt") displayValue = formatDateOnly(value);
            return (
              <Chip
                key={key}
                label={`${filterLabels[key]}: ${displayValue}`}
                removable
                onRemove={() => clearFilter(key)}
              />
            );
          })}
        </div>
        <div className="flex gap-2">
          {Object.keys(appliedFilters).length > 0 && (
            <Button icon="pi pi-times" onClick={clearAllFilters} className="ml-2 p-button-outlined" />
          )}
          <Button icon="pi pi-filter" onClick={() => setIsFilterVisible(true)} className="p-button-outlined" />
        </div>
      </div>
    );
  };

  const editExistingUser = (selectedData) => {
    setSelectedAccount(selectedData);
    setAccountDialog(true);
  }
  const confirmDeleteUser = (partner) => {
    setSelectedAccount(partner);
    setDeleteAccountDialog(true);
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment key={"table-data"}>
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-mr-2"
          onClick={() => { editExistingUser(rowData) }} />
        <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-danger"
          onClick={() => confirmDeleteUser(rowData)} />
      </React.Fragment>
    );
  };

  const deleteAccountDialogFooter = (
    <React.Fragment>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteAccountDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteAccount} />
    </React.Fragment>
  );
  const clearFilter = (filterKey) => {
    setAppliedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      delete updatedFilters[filterKey];
      return updatedFilters;
    });
  };

  const clearAllFilters = () => {
    setPagination({
      page: 1,
      rows: 10,
      sortField: "",
      sortOrder: "desc",
    });
    setAppliedFilters({});
  };

  const applyFilters = (filters) => {
    setAppliedFilters(filters);
  };

  const onPageChange = (event) => {
    setPagination((prev) => ({
      ...prev,
      page: event.page + 1,
      rows: event.rows,
    }));
  };

  const onSort = (event) => {
    setPagination((prev) => ({
      ...prev,
      sortField: event.sortField,
      sortOrder: event.sortOrder === 1 ? "asc" : "desc",
    }));
  };
  const CustomerAssociationBodyTemplate = (rowData) => {
    if (!rowData.customerAccounts || rowData.customerAccounts.length === 0) {
      return 'None';
    }
    return rowData.customerAccounts.map(account => account.name).join(', ');
  };

  const dateBodyTemplate = (rowData, field) => formatDateWithoutTimezone((rowData[field]));

  return (
    <div className="datatable-crud-demo">
      <div className="card">
        <h5>Partner Administration</h5>
        <Toolbar className="p-mb-4" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>
        <FiltersPanel appliedFilters={appliedFilters} visible={isFilterVisible}
          onHide={() => setIsFilterVisible(false)} onApply={applyFilters} />
        <DataTable value={partnerAccounts}
          lazy
          paginator
          first={(pagination.page - 1) * pagination.rows}
          rows={pagination.rows}
          totalRecords={totalRecords}
          loading={loading}
          onPage={onPageChange}
          onSort={onSort}
          sortField={pagination.sortField}
          sortOrder={pagination.sortOrder === "asc" ? 1 : -1}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} partners"
          rowsPerPageOptions={[10, 20, 50]}
        >
          <Column field="name" header="Name" sortable />
          <Column field="status" header="Status" sortable />
          <Column header="Associated Customer" body={CustomerAssociationBodyTemplate} />
          <Column field="description" header="Description" />
          <Column field="updatedAt" header="Updated At" sortable body={(rowData) => dateBodyTemplate(rowData, "updatedAt")} />
          <Column body={actionBodyTemplate} />
        </DataTable>
      </div>
      {accountDialog && <PartnerForm partnerDialog={accountDialog} hideDialog={hideDialog} onSubmit={handleSubmit}
        partnerAccounts={partnerAccounts} defaultValues={selectedAccount} />}
      <Dialog visible={deleteAccountDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteAccountDialogFooter} onHide={hideDeleteAccountDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
          {selectedAccount && <span>Are you sure you want to delete <b>{selectedAccount.name}</b>?</span>}
        </div>
      </Dialog>
    </div>
  );
};

export default PartnerAccountAdministration;
