import React, { useState } from "react";
import { BiSolidDashboard } from "react-icons/bi"; // Dashboard
import { MdBackup, MdStorage, MdBusiness } from "react-icons/md"; // Backups, DB Management
import { FaUsersCog, FaUserShield, FaHandshake, FaUserFriends } from "react-icons/fa"; // Users, Partners, Customers
import { RiBarChartBoxFill } from "react-icons/ri"; // Resource Usages
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import UserRole from "../../services/UserService";
import "../../assets/styles/Sidebar.css";


const SidebarMenu = () => {
    const role = UserRole(); // Get user role
    const location = useLocation(); // Get current route
    const [openSubMenu, setOpenSubMenu] = useState(false); // Track submenu state

    return (
        <div className="sidebar" style={{ width: "260px" }}> {/* Increased width */}
            <Sidebar
                backgroundColor="black"
                rootStyles={{ height: "100vh", color: "white", width: "100%" }}
            >
                <Menu
                    menuItemStyles={{
                        button: ({ active }) => ({
                            backgroundColor: active ? "#16a34a" : "black",
                            color: "white",
                            "&:hover": { backgroundColor: "#15803d" },
                        }),
                    }}
                >
                    {/* Account Management Menu with Submenu for customer admin and user */}
                    {(role === "CUSTOMER_ADMIN") && (
                        <>
                            <MenuItem
                                icon={<BiSolidDashboard />}
                                active={location.pathname === "/customerAdmin/dashboard"}
                                component={<Link to="/customerAdmin/dashboard" />}
                            >
                                Dashboard
                            </MenuItem>
                        </>
                    )}
                    {/* Account Management Menu with Submenu for admin */}
                    {(role === "SUPER_ADMIN" || role === "MSP_ADMIN") && (
                        <>
                            <MenuItem
                                icon={<BiSolidDashboard />}
                                active={location.pathname === "/dashboard"}
                                component={<Link to="/dashboard" />}
                            >
                                Dashboard
                            </MenuItem>
                            <MenuItem
                                icon={<MdBackup />}
                                active={location.pathname === "/customerdashboard"}
                                component={<Link to="/customerdashboard" />}
                            >
                                Customer Backups
                            </MenuItem>
                            <SubMenu
                                label="Account Management"
                                icon={<FaUsersCog />}
                                open={openSubMenu}
                                onClick={() => setOpenSubMenu(!openSubMenu)}
                            >
                                <MenuItem
                                    icon={<FaUserShield />}
                                active={location.pathname === "/useradminpanel"}
                                component={<Link to="/useradminpanel" />}
                            >
                                    User Management
                            </MenuItem>
                                <MenuItem
                                    icon={<FaHandshake />}
                                    active={location.pathname === "/partnersAccount"}
                                    component={<Link to="/partnersAccount" />}
                                >
                                    Partner Management
                                </MenuItem>
                                <MenuItem
                                    icon={<FaUserFriends />}
                                    active={location.pathname === "/customersAccount"}
                                    component={<Link to="/customersAccount" />}
                                >
                                    Customer Management
                                </MenuItem>
                                <MenuItem
                                    icon={<MdBusiness />}
                                    active={location.pathname === "/companies"}
                                    component={<Link to="/companies" />}
                                >
                                    Veeam Companies
                                </MenuItem>
                            </SubMenu>
                            <MenuItem
                                icon={<MdStorage />}
                                active={location.pathname === "/dbedit"}
                                component={<Link to="/dbedit" />}
                            >
                                DB Management
                            </MenuItem>
                            <MenuItem
                                icon={<RiBarChartBoxFill />}
                                active={location.pathname === "/resourceUsage"}
                                component={<Link to="/resourceUsage" />}
                            >
                                Resource Usages
                            </MenuItem>
                        </>
                    )}

                    {/* Other Menu Items */}

                </Menu>
            </Sidebar>
        </div>
    );
};

export default SidebarMenu;
