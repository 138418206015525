import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import logo from "../../assets/SDV-logo-horizontal@0.5x.png";
import { Menubar } from "primereact/menubar";
import { Avatar } from "primereact/avatar";
import { TieredMenu } from "primereact/tieredmenu";
import "../../assets/styles/TopNavbar.css";
import { clearAuth, getToken } from "../../utils/helpers";

const TopNavbar = () => {
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const logout = () => {
    if (window.confirm("Are you sure you want to logout?")) {
      clearAuth()
      navigate("/login");
    }
  };

  const getCurrentUser = () => {
    const jwt = getToken();
    if (!jwt) return null;

    try {
      return jwtDecode(jwt);
    } catch {
      return null;
    }
  };

  const currentUser = getCurrentUser();
  const userName = currentUser
    ? currentUser.name || `${currentUser.firstName || ""} ${currentUser.lastName || ""}`.trim()
    : "Guest";

  const userMenuItems = [
    {
      label: "Profile Settings",
      icon: "pi pi-user",
      command: () => navigate("/profile"),
    },
    {
      label: "Logout",
      icon: "pi pi-sign-out",
      command: logout,
    },
  ];

  const startTemplate = (
    <div className="p-d-flex p-ai-center">
      <img src={logo} alt="logo" className="topnav-logo p-mr-2" />
    </div>
  );

  const endTemplate = (
    <div className="p-d-flex p-ai-center p-jc-end" style={{ width: "100%" }}>
      <span className="p-mr-3 topnav-username">{userName}</span>
      <Avatar
        label={userName.charAt(0).toUpperCase()}
        shape="circle"
        className="p-mr-2 topnav-avatar"
        style={{ backgroundColor: "#2196F3", color: "#ffffff", cursor: "pointer" }}
        onClick={(event) => menuRef.current?.toggle(event)}
      />
      <TieredMenu model={userMenuItems} popup={true} ref={menuRef} id="user_menu" />
    </div>
  );

  return <Menubar start={startTemplate} end={endTemplate} className="topnav-menubar" />;
};

export default TopNavbar;
