export function isSessionExpiredError(error) {
    if (error?.response?.status === 401 || error?.response?.status === 419 || error?.response?.status === 403) {
        return true; // Session expired
    }
    return false;
}

export const formatDateOnly = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
        year: "numeric",
        day: "2-digit",
        month: "long",
    });
};


export const formatDateWithoutTimezone = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.toLocaleString("en-US", { month: "long", timeZone: "UTC" });
    const year = date.getUTCFullYear();

    const hours = date.getUTCHours() % 12 || 12;
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const seconds = date.getUTCSeconds().toString().padStart(2, "0");
    const amPm = date.getUTCHours() >= 12 ? "PM" : "AM";

    return `${month} ${day}, ${year} at ${hours}:${minutes}:${seconds} ${amPm}`;
};

export const generateAccountIdentifier = (name) => {
    if (!name) return "";

    const words = name.trim().toUpperCase().split(/\s+/);
    const firstWord = words[0] || "";
    const secondWord = words[1] || "";

    let firstPart = firstWord.substring(0, 3).padEnd(3, firstWord);
    let secondPart = secondWord.substring(0, 3).padEnd(3, firstWord);

    return (firstPart + secondPart).substring(0, 6);
};
