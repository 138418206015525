import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Chart } from "primereact/chart";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Skeleton } from "primereact/skeleton";
import { Tag } from "primereact/tag";
import { useEffect, useState, useTransition } from "react";
import { FaExclamationCircle } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import DataTableSkeleton from "../../Skeletons/DataTable";
import { formatDateWithoutTimezone, isSessionExpiredError } from "../../utils/Common";
import { showToast } from "../../utils/toastService";

const BackUpServerLogDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [logDetails, setLogDetails] = useState(null);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [isPending, startTransition] = useTransition();

    useEffect(() => {
        const fetchData = async () => {
            startTransition(async () => {
                try {
                    const response = await api.get(`/backup-failures/${id}`);
                    setLogDetails(response.data?.data || null);
                } catch (error) {
                    console.error("Error fetching backup server log details:", error);
                    if (isSessionExpiredError(error)) {
                        showToast("warn", "Session Expired", "Your session has expired. Please log in again.");
                    } else {
                        showToast("error", "Error", "Failed to fetch backup sessions.");
                    }
                }
            });
        };
        fetchData();
    }, [id]);

    if (isPending) {
        return (
            <Card className="p-5 shadow-md">
                <div className="card">
                    <Skeleton width="100%" height="150px"></Skeleton>
                </div>
                <div className="mt-4">
                    <DataTableSkeleton></DataTableSkeleton>
                </div>
            </Card>
        );
    }
    if (!logDetails) return (
        <Card className="mt-4 p-5 text-center shadow-md border border-gray-200">
            <FaExclamationCircle className="text-red-500 text-4xl mx-auto mb-3" />
            <h3 className="text-lg font-semibold text-gray-700">No Backup Details Found</h3>
            <p className="text-gray-500">There are no details available for this backup log.</p>
        </Card>
    );

    const { name, status, description, createdBy,
        creationTime,
        failureMessage,
        type, lastRun, lastEndTime, lastDuration, processingRate, avgDuration, transferredData,
        backupChainSize, scheduleType, targetType, destination, retentionLimit,
        lastSessionTasks, retentionLimitType } = logDetails;

    const getChartData = (task) => ({
        labels: ["Total Objects", "Processed Objects", "Read Data", "Transferred Data"],
        datasets: [
            {
                label: task.objectName,
                data: [
                    task.totalObjects || 0,
                    task.processedObjects || 0,
                    task.readDataSize || 0,
                    task.transferredDataSize || 0,
                ],
                backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
            },
        ],
    });
    const statusBodyTemplate = (rowData) => {
        let severity;
        switch (rowData.status) {
            case 'Failed':
                severity = 'danger';
                break;
            case 'Warning':
                severity = 'warning';
                break;
            default:
                severity = null;
        }
        return <Tag value={rowData.status} severity={severity} />;
    };
    const formatStorageSize = (mb) => {
        if (mb >= 1_000_000) {
            return `${(mb / 1_000_000).toFixed(2)} TB`;
        } else if (mb >= 1_000) {
            return `${(mb / 1_000).toFixed(2)} GB`;
        } else {
            return `${mb} MB`;
        }
    };


    const formatDuration = (seconds) => {
        if (seconds >= 3600) return Math.floor(seconds / 3600) + " hr " + Math.floor((seconds % 3600) / 60) + " min";
        if (seconds >= 60) return Math.floor(seconds / 60) + " min " + (seconds % 60) + " sec";
        return seconds + " sec";
    };

    return (
        <div className="p-5">
            <Button className="m-2" label="Back" icon="pi pi-arrow-left" onClick={() => navigate("/customerdashboard")} />
            <Card title="Backup Failure Details">
                {/* Header */}
                <div className="flex justify-between items-center">
                    <h2 className="text-xl font-semibold">{name}</h2>
                    <Tag className="ml-4 mb-2" severity={status === "Failed" ? "danger" : "warning"}>{status}</Tag>
                </div>

                <p className="text-gray-600">{description}</p>
                <p><strong>Created By:</strong> {createdBy}</p>
                <p><strong>Creation Time:</strong> {formatDateWithoutTimezone(creationTime).toLocaleString()}</p>

                {/* Data Grid (3 columns) */}
                <div className="mt-4 grid grid-cols-3 gap-4">
                    <p><strong>Type:</strong> {type}</p>
                    <p><strong>Last Run:</strong> {formatDateWithoutTimezone(lastRun).toLocaleString()}</p>
                    <p><strong>Last End Time:</strong> {formatDateWithoutTimezone(lastEndTime).toLocaleString()}</p>

                    <p><strong>Last Duration:</strong> {formatDuration(lastDuration)}</p>
                    <p><strong>Processing Rate:</strong> {formatStorageSize(processingRate)}</p>
                    <p><strong>Avg Duration:</strong> {formatDuration(avgDuration)}</p>

                    <p><strong>Transferred Data:</strong> {formatStorageSize(transferredData)}</p>
                    <p><strong>Backup Chain Size:</strong> {formatStorageSize(backupChainSize)}</p>
                    <p><strong>Schedule Type:</strong> {scheduleType}</p>

                    <p><strong>Target Type:</strong> {targetType}</p>
                    <p><strong>Destination:</strong> {destination}</p>
                    <p><strong>Retention Limit:</strong> {retentionLimit} {retentionLimitType ? retentionLimitType : null} </p>
                </div>
            </Card>



            {failureMessage && (<Card title="Failure Messages" className="mt-4">
                <pre className="bg-gray-100 p-3 rounded overflow-auto">{failureMessage}</pre>
            </Card>)}

            <Card title="Last Session Tasks" className="mt-4">
                <DataTable value={lastSessionTasks} paginator rows={10} className="p-datatable-striped">
                    <Column field="objectName" header="Object Name" sortable />
                    <Column field="startTime" header="Start Time" body={(row) => formatDateWithoutTimezone(row.startTime)} />
                    <Column field="endTime" header="End Time" body={(row) => formatDateWithoutTimezone(row.endTime)} />
                    <Column field="status" header="Status" body={statusBodyTemplate} sortable></Column>
                    <Column field="failureMessages" header="Errors" body={(row) => (
                        <ul className="text-red-500 list-disc pl-5">
                            {row.failureMessages.map((msg, index) => (<li key={index}>{msg}</li>))}
                        </ul>
                    )} />
                    <Column
                        header="View Graph"
                        body={(row) => {
                            const chartData = getChartData(row);
                            const hasData = chartData.datasets.some((dataset) => dataset.data.some((value) => value > 0));

                            return (
                                <Button
                                    icon="pi pi-eye"
                                    label="View"
                                    className="p-button-text p-button-sm"
                                    onClick={() => {
                                        if (hasData) {
                                            setSelectedTask(row);
                                            setDialogVisible(true);
                                        }
                                    }}
                                    disabled={!hasData} // Disable button if no data is present
                                />
                            );
                        }}
                    />
                </DataTable>
            </Card>

            <Dialog
                visible={dialogVisible}
                onHide={() => setDialogVisible(false)}
                header="Backup Task Chart"
                modal
                style={{ width: "60vw" }}
            >
                {selectedTask ? (
                    <div className="p-4">
                        <h3 className="text-lg font-semibold mb-3">{selectedTask.objectName}</h3>
                        <Chart type="bar" data={getChartData(selectedTask)} options={{ responsive: true }} />
                    </div>
                ) : (
                    <p>No data available</p>
                )}
            </Dialog>
        </div>
    );
};

export default BackUpServerLogDetails;
