
import React from 'react';
import { Skeleton } from 'primereact/skeleton';

export default function CardSkeleton() {
    return (
        <div className="card">
            <Skeleton width="100%" height="150px"></Skeleton>
        </div>
    );
}
