import React from "react";
import VeeamCompanyAdministration from "../../../Components/AdminManagement/VeeamCompaniesDashboard/VeeamCompanyAdministration";

const CompaniesManagement = () => {
    return (
        <div className="p-grid p-nogutter user-admin-panel">
            <div className="p-col content-column p-p-3">
                <div className="content-area">
                    <VeeamCompanyAdministration />
                </div>
            </div>
        </div>
    );
};

export default CompaniesManagement;
