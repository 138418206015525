// src/Components/AdminDashboard/CustomerBackupStatusTable.jsx

import { Button } from "primereact/button";
import { Chip } from 'primereact/chip';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tag } from 'primereact/tag';
import { Toolbar } from "primereact/toolbar";
import { default as React, useCallback, useEffect, useState } from 'react';
import "../assets/styles/BackupSessions.css";
import api from '../services/api';
import DataTableSkeleton from '../Skeletons/DataTable';
import { isSessionExpiredError } from '../utils/Common';
import { showToast } from '../utils/toastService';
import FiltersPanel from "./Filters/CustomerBackupStatus/FiltersPanel";

const CustomerBackupStatusTable = ({ customerId }) => {
  const [backupJobs, setBackupJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,      // API page (starts from 1)
    rows: 10,     // Rows per page (default 10)
    sortField: "",
    sortOrder: "desc",  // 'asc' or 'desc'
  });


  const fetchCustomerStatus = useCallback(async () => {
    if (!customerId) return;

    setLoading(true);
    try {
      const params = {
        page: pagination.page,
        limit: pagination.rows,
        sortField: pagination.sortField,
        sortOrder: pagination.sortOrder,
        ...appliedFilters,
      };

      const response = await api.get(`/veeambackupjobs/tenant/${customerId}`, { params });
      setBackupJobs(response.data.data);
      setTotalRecords(response.data?.pagination?.totalRecords);
    } catch (error) {
      console.error('Error fetching customer backup status:', error);
      if (isSessionExpiredError(error)) {
        showToast("warn", "Session Expired", "Your session has expired. Please log in again.");
      } else {
        showToast('error', 'Error', 'Failed to fetch customer backup status.');
      }
    } finally {
      setLoading(false);
    }
  }, [customerId, pagination, appliedFilters]);

  useEffect(() => {
    fetchCustomerStatus();
  }, [fetchCustomerStatus]);

  const typeBodyTemplate = (rowData) => {
    const type = rowData.type || 'Unknown';
    let severity = 'info';

    // Map job types to colors. Adjust as needed.
    // For example: 'Backup' => success, 'Replication' => warning, 'Copy' => info
    switch (type.toLowerCase()) {
      case 'backup':
        severity = 'info';
        break;
      case 'replica':
        severity = 'success';
        break;
      case 'simplebackupcopypolicy':
        severity = 'warning';
        break;
      default:
        severity = 'info';
    }

    return <Tag value={type} severity={severity} />;
  };

  const statusBodyTemplate = (rowData) => {
    const status = rowData.status || 'Unknown';
    let severity = 'info';

    // Map job status to colors. Adjust as needed.
    // Example: 'Success' => success, 'Warning' => warning, 'Failed' => danger
    switch (status.toLowerCase()) {
      case 'success':
        severity = 'success';
        break;
      case 'warning':
        severity = 'warning';
        break;
      case 'failed':
        severity = 'danger';
        break;
      default:
        severity = 'info';
    }

    return <Tag value={status} severity={severity} />;
  };
  const rightToolbarTemplate = () => {
    return (
      <div className="flex justify-between items-center mb-3">
        <div className="flex gap-2">
          {appliedFilters.name && (
            <Chip label={`Job: ${appliedFilters.name}`} removable onRemove={() => clearFilter("name")} />
          )}
          {appliedFilters.status && (
            <Chip label={`Status: ${appliedFilters.status}`} removable onRemove={() => clearFilter("status")} />
          )}
          {appliedFilters.type && (
            <Chip label={`Job Type: ${appliedFilters.type}`} removable onRemove={() => clearFilter("type")} />
          )}
        </div>
        <div className="flex gap-2">
          {Object.keys(appliedFilters).length > 0 && (
            <Button icon="pi pi-times" onClick={clearAllFilters} className="ml-2 p-button-outlined" />
          )}
          <Button icon="pi pi-filter" onClick={() => setIsFilterVisible(true)} className="p-button-outlined" />
        </div>
      </div>
    );
  };
  const onPageChange = (event) => {
    setPagination((prev) => ({
      ...prev,
      page: event.page + 1,
      rows: event.rows,
    }));
  };

  const onSort = (event) => {
    setPagination((prev) => ({
      ...prev,
      sortField: event.sortField,
      sortOrder: event.sortOrder === 1 ? "asc" : "desc",
    }));
  };
  const clearFilter = (filterKey) => {
    setAppliedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      delete updatedFilters[filterKey];
      return updatedFilters;
    });
  };

  const clearAllFilters = () => {
    setAppliedFilters({});
  };

  const applyFilters = (filters) => {
    setAppliedFilters(filters);
  };
  return (
    <div>
      {
        loading ? (<DataTableSkeleton />) : (
          <>
            <Toolbar className="p-mb-4" start={<h3>Customer Backup Status</h3>} end={rightToolbarTemplate}></Toolbar>
            <FiltersPanel appliedFilters={appliedFilters} visible={isFilterVisible} onHide={() => setIsFilterVisible(false)} onApply={applyFilters} />
            <DataTable
              value={backupJobs}
              lazy
              paginator
              first={(pagination.page - 1) * pagination.rows}
              rows={pagination.rows}
              totalRecords={totalRecords}
              loading={loading}
              onPage={onPageChange}
              onSort={onSort}
              sortField={pagination.sortField}
              sortOrder={pagination.sortOrder === "asc" ? 1 : -1}
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} customer  backups"
              rowsPerPageOptions={[10, 20, 50]}
            >
          <Column field="name" header="Name" sortable />
              <Column field="type" header="Job Type" body={typeBodyTemplate} sortable />
          <Column field="status" header="Status" body={statusBodyTemplate} sortable />
              <Column field="latestBackupSession" header="Last Status" body={statusBodyTemplate} />
            </DataTable>
          </>
        )
      }

    </div>
  );
};

export default CustomerBackupStatusTable;
