// src/Components/CustomerDashboard/ReplicaGrid.jsx

import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState, useTransition } from 'react';
import api from '../services/api'; // Adjust import path if needed
import DataTableSkeleton from '../Skeletons/DataTable';
import { isSessionExpiredError } from '../utils/Common';
import { showToast } from '../utils/toastService';

const ReplicaGrid = ({ customerId }) => {
  const [replicas, setReplicas] = useState([]);
  const [selectedReplica, setSelectedReplica] = useState(null);
  const [isReplicaDailog, setIsReplicaDailog] = useState(false);
  const [isPending, startTransition] = useTransition();

  useEffect(() => {
    const fetchReplicas = async () => {
      startTransition(async () => {
        try {
          const response = await api.get(`/veeamreplicas/tenant/${customerId}`);
          setReplicas(response.data || []);
        } catch (error) {
          if (isSessionExpiredError(error)) {
            showToast('warn', 'Session Expired', 'Your session has expired. Please log in again.');
          } else {
            showToast('error', 'Error', 'Failed to replicas servers.');
          };
          console.error('Error fetching replicas:', error);
        }
      });
    };
    fetchReplicas();
  }, [customerId]);

  /**
   * Returns a background color based on the replica's state.
   * Adjust these as desired.
   */
  const getColorByState = (state) => {
    if (!state) return '#9E9E9E'; // Gray for unknown
    switch (state.toLowerCase()) {
      case 'ready':
        return '#4CAF50'; // Green
      case 'failed':
      case 'critical':
        return '#F44336'; // Red
      case 'warning':
        return '#FFC107'; // Amber
      default:
        return '#9E9E9E'; // Gray
    }
  };

  // Handler for when a replica cell is clicked
  const handleReplicaClick = (replica) => {
    setSelectedReplica(replica);
    setIsReplicaDailog(true);
  };

  return (
    <div>
      <h2>Replica List</h2>

      {isPending ? (
        <DataTableSkeleton />
      ) : replicas.length > 0 ? ( // Only show the grid if replicas exist
        <div
          style={{
            display: 'grid',
              gridTemplateColumns: `repeat(${4}, 1fr)`,
            gap: '1rem',
          }}
        >
          {replicas.map((replica, index) => {
            const bgColor = getColorByState(replica.state);
            return (
              <button
                key={replica.id || index}
                onClick={() => handleReplicaClick(replica)}
                style={{
                  backgroundColor: bgColor,
                  border: 'none',
                  borderRadius: '4px',
                  color: '#fff',
                  textAlign: 'center',
                  padding: '1rem',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
              >
                {replica.name || 'Unnamed Replica'}
              </button>
            );
          })}
        </div>
      ) : (
        <p className='text-center'>No replicas available.</p>
      )}
      <Dialog
        visible={isReplicaDailog}
        onHide={() => setIsReplicaDailog(false)}
        style={{ width: "30vw" }}
      >
        <h3>{selectedReplica?.name || 'Unnamed Replica'}</h3>
        <p>
          <strong>State:</strong> {selectedReplica?.state}
        </p>
      </Dialog>

    </div>
  );
};
export default ReplicaGrid;
