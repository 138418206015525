import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/logo180.png";
import config from "../../../config";
import { getUserRole, setToken } from "../../../utils/helpers";
import styles from "./Login.module.css";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (message && message.type === "success") {
      const timer = setTimeout(() => setMessage(null), 2000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const onSubmit = async (data) => {
    try {
      setMessage({ data: "Logging in...", type: "warning" });

      const response = await fetch(`${config.baseUrl}/auth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Login failed");
      }

      const resData = await response.json();
      setToken(resData.token, null);
      setMessage({ data: "Login successful! Redirecting...", type: "success" });
      const userRole = resData.role || getUserRole();
      if (userRole === "SUPER_ADMIN") {
        navigate("/dashboard");
      } else if (userRole === "CUSTOMER_USER") {
        navigate("/customerlandingpage");
      } else if (userRole === "CUSTOMER_ADMIN") {
        navigate("/customerAdmin/dashboard");
      } else {
        navigate("/login"); // Fallback for unknown roles
      }
    } catch (error) {
      console.error("Login error:", error);
      setMessage({ data: error.message || "An unexpected error occurred", type: "danger" });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.loginFormContainer}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Logo" className={styles.logo} />
        </div>

        {message && (
          <div className={`${styles.alert} ${styles[message.type]}`}>
            {message.data}
            <button className={styles.closeButton} onClick={() => setMessage(null)}>&times;</button>
          </div>
        )}

        <fieldset className={styles.fieldset}>
          <legend className={styles.legend}>SDV Portal Login</legend>
          <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <div className={styles.formGroup}>
              <label htmlFor="email" className={styles.label}>
                Email Address <span className={styles.required}>*</span>
              </label>
              <input
                id="email"
                type="email"
                className={`${styles.input} ${errors.email ? styles.inputError : ""}`}
                placeholder="Enter email address"
                {...register("email", {
                  required: "Please enter your email",
                  pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: "Invalid email address" },
                })}
              />
              {errors.email && <span className={styles.errorMessage}>{errors.email.message}</span>}
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="password" className={styles.label}>
                Password <span className={styles.required}>*</span>
              </label>
              <input
                id="password"
                type="password"
                className={`${styles.input} ${errors.password ? styles.inputError : ""}`}
                placeholder="Enter password"
                {...register("password", { required: "Please enter your password" })}
              />
              {errors.password && <span className={styles.errorMessage}>{errors.password.message}</span>}
            </div>

            <button type="submit" className={styles.submitButton}>Login</button>
            <Link to="/register" className={styles.registerLink}>Don't have an account? Register</Link>
          </form>
        </fieldset>
      </div>
    </div>
  );
};

export default Login;
